import Vue from 'vue';
import { Context } from '@nuxt/types';
import { isBefore, isSameDay, startOfToday, format, addDays } from 'date-fns';
import _ from 'lodash';
import {
  SiteType,
  DriverAssignmentType,
  DriverType,
  MarginType,
  OrderCreatedVia,
  OrderDisposalSiteAssignmentType,
  OrderStatus,
  PreloadStatus,
  EmploymentStatus,
  OrderSchedulingPriority,
} from '~/framework/domain/typeAliases';
import {
  ClientsByKeywordsCondition,
  ClientsByKeywordsOrder,
  CreateOrderRecurringSettingsInput,
  GenerationSitesByKeywordsCondition,
  GenerationSitesByKeywordsOrder,
  OrderPlanInput,
} from '~/framework/server-api/typeAliases';

import { Maybe, PersistentId } from '~/framework/typeAliases';
import { AggregatedDisposalSiteEntity as IDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';

import { mapEntity } from '~/framework/core/mapper';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { GenerationSiteTaskCategory } from '~/framework/view-models/generationSiteTaskCategory';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import {
  getDefaultInitialErpOrderFormValues,
  getInitialFormValuesByOrder,
  IFormValues,
  IOrderFormPanelOption,
  OrderScrollTarget,
} from '~/framework/view-models/panels/orderFormPanel';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import { IOrderValidation, OrderValidation } from '~/framework/view-models/orderValidation';
import { Client } from '~/components/common/r-lazy-searchable-pulldown/client';
import { GenerationSite } from '~/components/common/r-lazy-searchable-pulldown/generationSite';
import { arrayFromMaybe } from '~/framework/array';
import { Features } from '~/framework/featureManager';
import { dateToMd, dateToYyMdDaysOfWeek, dateToYymmdd, formatDateForField } from '~/framework/services/date/date';
import {
  buildDefault,
  IRecurringOrderSettings,
} from '~/components/panels/schedule/r-order-form/r-recurring-order-settings-dialog/recurringOrderSettings';
import { DateCollectablePeriodItem } from '~/components/panels/schedule/r-order-form/dateCollectablePeriodItem';
import {
  DialogMode,
  RecurringOrderSettingsDialogValue,
} from '~/components/panels/schedule/r-order-form/r-recurring-order-settings-dialog/recurringOrderSettingsDialogValue';
import DateCollectablePeriodTypes from '~/components/panels/schedule/r-order-form/dateCollectablePeriodTypes';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import { WasteTypeEntity } from '~/framework/domain/masters/waste-type/wasteTypeEntity';
import { OrderGroupEntity } from '~/framework/domain/masters/order-group/orderGroupEntity';
import { CarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';
import { AggregatedCarEntity as ICarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { ClientEntity } from '~/framework/domain/masters/client/clientEntity';
import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import { DriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/driverAttendanceEntity';
import { ContainerTypeEntity } from '~/framework/domain/masters/container-type/containerTypeEntity';
import { GenerationSiteEntity } from '~/framework/domain/masters/generation-site/generationSiteEntity';
import {
  IOrderApplicationService,
  IValidateOrderData,
  orderSymbol,
} from '~/framework/application/schedule/order/orderApplicationService';
import { BusinessDaysService, IBusinessDaysService } from '~/framework/services/business-days/businessDaysService';
import { AggregatedOrderEntity as IOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { AggregatedCarTypeEntity } from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { getHoursAndMinutesOf } from '~/framework/services/date-time/date-time';
import { getRoutableOrderDisplayName } from '~/components/panels/schedule/r-order-form/routingGroup';
import RDateCollectablePeriodTypeInput from '~/components/panels/schedule/r-order-form/RDateCollectablePeriodTypeInput.vue';
import RDateCollectablePeriodBatchRegisterDialog from '~/components/panels/schedule/r-order-form/RDateCollectablePeriodBatchRegisterDialog.vue';
import ROrderAcceptanceCheckDialog from '~/components/panels/schedule/r-order-form/r-order-acceptance-check/ROrderAcceptanceCheckDialog.vue';
import RRecurringOrderSettingsDialog from '~/components/panels/schedule/r-order-form/r-recurring-order-settings-dialog/RRecurringOrderSettingsDialog.vue';
import RRecurringOrderRegisterDialog from '~/components/panels/schedule/r-order-form/RRecurringOrderRegisterDialog.vue';
import RRecurringOrderDeleteDialog from '~/components/panels/schedule/r-order-form/RRecurringOrderDeleteDialog.vue';
import RDateCollectablePeriodInput from '~/components/panels/schedule/r-order-form/RDateCollectablePeriodInput.vue';
import RDisposalSites from '~/components/panels/schedule/r-order-form/RDisposalSites.vue';
import DisabledReason from '~/components/panels/schedule/r-order-form/disabledReasonEnum';
import RDriverAssignment from '~/components/panels/schedule/r-order-form/RDriverAssignment.vue';
import RTaskTypeSelect from '~/components/panels/schedule/r-order-form/RTaskTypeSelect.vue';
import RGenerationSiteOptions from '~/components/panels/schedule/r-order-form/RGenerationSiteOptions.vue';
import RRouteCollectionInput from '~/components/panels/schedule/r-order-form/RRouteCollectionInput.vue';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { OfficeSettingEntity } from '~/framework/domain/masters/office-setting/officeSettingEntity';
import { OrderFormBase } from '~/framework/view-models/order-form-base/orderFormBase';
import {
  DesignatedTimePeriodOption,
  DesignatedTimePeriodOptionId,
  DistinctTimeOption,
  DistinctTimeOptionId,
  ICollectablePeriodTemplateOption,
} from '~/framework/view-models/collectablePeriodTemplateOption';
import { indexToDayOfWeek } from '~/framework/view-models/daysOfWeek';
import { IPreloadOption, preloadOptions } from '~/framework/view-models/preloadOption';
import { InfeasibilityReasons } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { IOrderAcceptanceCheckData } from '~/framework/application/schedule/order/order-acceptance-check/orderAcceptanceCheckApplicationService';
import {
  ContainerTypeTaskItemFactory,
  IGenerationSiteTaskItem,
} from '~/components/panels/schedule/r-order-form/r-generation-site-task-field/generationSiteTaskItem';
import { ContainerTypeTaskDuration } from '~/components/panels/schedule/r-order-form/containerTypeTaskDuration';
import { IrregularTaskItemFactory } from '~/components/panels/schedule/r-order-form/irregularTaskItem';
import RScheduleOrderViaReservationCancelConfirmDialog from '~/components/pages/schedule/r-schedule-order/RScheduleOrderViaReservationCancelConfirmDialog.vue';
import RScheduleOrderViaReservationDeleteConfirmDialog from '~/components/pages/schedule/r-schedule-order/RScheduleOrderViaReservationDeleteConfirmDialog.vue';
import {
  getAssignableDriverIdsByDriverType,
  IOrderAssignableDriver,
} from '~/framework/server-api/schedule/order/driver/assignableDriver';
import RPlanCandidateDatesDeleteDialog from '~/components/panels/schedule/r-order-form/r-plan-candidate-dates-dialog/RPlanCandidateDatesDeleteDialog.vue';

import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';
import RRecordStamperForForm from '~/components/common/r-record-stamper-for-form/RRecordStamperForForm.vue';
import { IOrderAssignedDisposalSite } from '~/framework/server-api/schedule/order/disposal-site/disposalSite';
import { convertOrderPlanToOrderPlanInput } from '~/framework/domain/schedule/order/orderUtils';
import { sanitizeNaturalNumber, sanitizeHours, sanitizeMinutes } from '~/framework/view-models/ruleLogics';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventNames,
  RinEventFormTargetTypes,
  PageNames,
  ShortcutKeyParams,
  RinEventFormComponentParam,
} from '~/framework/services/rin-events/rinEventParams';
import RUploadedFile from '~/components/common/r-uploaded-file/RUploadedFile.vue';
import { IErpClientData } from '~/framework/server-api/masters/erpClient';
import RErpOrderForm from '~/components/common/r-erp-order-form/RErpOrderForm.vue';
import { ErpOrderForm, ErpOrderItemForm } from '~/components/common/r-erp-order-form/RErpOrderForm';
import { IErpOrderData } from '~/framework/server-api/schedule/order/erpOrder';
import {
  ErpOrderApplicationService,
  erpOrderApplicationServiceSymbol,
} from '~/framework/application/schedule/order/erpOrderApplicationService';
import { ensure } from '~/framework/core/value';
import { AggregatedGenerationSiteTaskEntity } from '~/framework/domain/schedule/order/generation-site-task/aggregatedGenerationSiteTaskEntity';
import {
  DriverApplicationService,
  driverSymbol,
} from '~/framework/application/masters/driver/driverApplicationService';
import { useRinLocalStorage } from '~/framework/services/localStorage';
import ROrderGenerationSiteForm from '~/components/panels/schedule/r-order-form/ROrderGenerationSiteForm.vue';
import { generationSiteSymbol } from '~/framework/application/masters/generation-site/generationSiteApplicationService';
import { AggregatedGenerationSiteEntity } from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteEntity';
import { IOrderDefaultService, OrderDefaultService } from '~/framework/services/order-default/orderDefaultService';
import { getOrderFormData } from './getOrderFromData';
import { driverAttendanceSymbol } from '~/framework/application/masters/driver-attendance/driverAttendanceApplicationService';
import { clientSymbol } from '~/framework/application/masters/client/clientApplicationService';
import { ICreateData } from '~/framework/server-api/schedule/order/createOrders';
import { IUpdateData } from '~/framework/server-api/schedule/order/updateOrders';

const representativeDateCollectablePeriodFirst = 0;
const representativeDateCollectablePeriodLast = 5;

const representativeOrderPlanCollectablePeriodFirst = 0;
const representativeOrderPlanCollectablePeriodLast = 5;

export enum FormMode {
  Register,
  Edit,
}

export enum EnteredGenerationSiteNameState {
  Exist,
  New,
  Searching,
}

/**
 * 排出場からデフォルトの値を取ってくるフィールドがデフォルト値のままかどうか
 * デフォルト値そのままであれば true
 * ユーザーがデフォルト値以外に変更していれば false
 */
type DefaultFormValueStatuses = {
  assignableCarTypeIds: boolean;
  assignedCarId: boolean;
  driverAssignmentType: boolean;
  assignableDrivers: boolean;
  disposalSiteIds: boolean;
  avoidHighways: boolean;
  routeCollectionAllowed: boolean;
  collectablePeriod: boolean;
  preloadStatus: boolean;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: boolean;
  marginOfFixedArrivalTime: boolean;
};

export class OrderForm extends OrderFormBase<IFormValues> {
  readonly formMode: FormMode;
  readonly title: string;
  readonly initialFormValues: IFormValues;
  /**
   * v-model for v-form
   */
  isFormValid: boolean = false;

  // date-time
  readonly collectablePeriodTemplateEntities: CollectablePeriodTemplateEntity[];
  readonly collectablePeriodTemplates: ICollectablePeriodTemplateOption[];
  readonly collectablePeriodTemplateMap: Map<PersistentId, ICollectablePeriodTemplateOption>;
  dateCollectablePeriodType!: DateCollectablePeriodTypes; // `initializeFormValues` で設定される
  dateCollectablePeriodItems!: DateCollectablePeriodItem[]; // `initializeFormValues` で設定される
  recurringOrderSettings: Maybe<IRecurringOrderSettings>;

  /** 複数候補日の情報 */
  orderPlanCollectablePeriodItems!: DateCollectablePeriodItem[];
  /** 編集中の複数候補日の情報 */
  editOrderPlanCollectablePeriodItems!: DateCollectablePeriodItem[];

  // preload
  preloadStatus!: PreloadStatus; // `initializeFormValues` で設定される
  preloadOptions: IPreloadOption[];

  // order entities
  readonly order: Maybe<IOrderEntity>;
  createdOrder: Maybe<IOrderEntity>;
  updatedOrder: Maybe<IOrderEntity>;
  taskTypes: TaskTypeEntity[];

  // disabled/readonly
  get isPreloadDisabled(): boolean {
    return this.generationSiteId === undefined;
  }

  // attachments
  inputAttachments: File[];
  attachmentsToAdd: File[];
  attachmentsToRemove: string[];

  // erpOrder
  canUseErp: boolean;
  erpOrder: Maybe<IErpOrderData>;
  erpOrderForm: Maybe<ErpOrderForm>;

  constructor(
    clientDefaultCondition: ClientsByKeywordsCondition,
    clientLoader: Client,
    generationSiteDefaultCondition: GenerationSitesByKeywordsCondition,
    generationSiteLoader: GenerationSite,
    client: Maybe<ClientEntity>,
    officeSetting: OfficeSettingEntity,
    generationSite: Maybe<GenerationSiteEntity>,
    orderGroups: OrderGroupEntity[],
    disposalSites: IDisposalSiteEntity[],
    wasteTypes: WasteTypeEntity[],
    containerTypes: ContainerTypeEntity[],
    containerTypeTaskTypes: ContainerTypeTaskTypeEntity[],
    packingStyles: PackingStyleEntity[],
    collectablePeriodTemplates: CollectablePeriodTemplateEntity[],
    drivers: DriverEntity[],
    carTypes: AggregatedCarTypeEntity[],
    cars: ICarEntity[],
    assignableDriverAttendances: Maybe<DriverAttendanceEntity[]>,
    order: Maybe<IOrderEntity>,
    orderValidation: IOrderValidation,
    initialFormValues: Maybe<IFormValues>,
    title: Maybe<string>,
    taskTypes: TaskTypeEntity[],
    canUseErp: boolean,
    transportationClients: Maybe<IErpClientData[]>,
    erpOrder: Maybe<IErpOrderData>
  ) {
    super(
      clientDefaultCondition,
      clientLoader,
      generationSiteDefaultCondition,
      generationSiteLoader,
      officeSetting,
      orderGroups,
      disposalSites,
      wasteTypes,
      containerTypes,
      containerTypeTaskTypes,
      packingStyles,
      drivers,
      carTypes,
      cars,
      assignableDriverAttendances,
      orderValidation,
      taskTypes
    );

    // Form general
    this.order = order;
    this.formMode = order === undefined ? FormMode.Register : FormMode.Edit;
    this.title = title ?? this.formMode === FormMode.Register ? '受注の登録' : '受注の編集';
    // erp
    this.canUseErp = canUseErp;
    this.erpOrder = erpOrder;

    // Master data
    this.collectablePeriodTemplateEntities = collectablePeriodTemplates;
    this.collectablePeriodTemplates = [DistinctTimeOption, ...collectablePeriodTemplates, DesignatedTimePeriodOption];
    this.collectablePeriodTemplateMap = mapEntity(this.collectablePeriodTemplates);
    this.preloadOptions = preloadOptions;
    // 排出場のみ
    this.taskTypes = taskTypes.filter((task) => task.baseTaskType.siteType === SiteType.GenerationSite);
    // attachments
    this.inputAttachments = [];
    this.attachmentsToAdd = [];
    this.attachmentsToRemove = [];

    // Initialize form values
    this.initialFormValues = initialFormValues ?? this.getInitialFormValues(transportationClients);
    this.initializeFormValues(this.initialFormValues, client, generationSite);
  }

  get isDirty(): boolean {
    const isIrregularTaskDirty =
      this.irregularTask.name !== this.initialFormValues.irregularTask.name ||
      this.irregularTask.skipDisposalSite !== this.initialFormValues.irregularTask.skipDisposalSite ||
      this.irregularTask.durationAtGenerationSite !== this.initialFormValues.irregularTask.durationAtGenerationSite ||
      this.irregularTask.hoursAtGenerationSite !== this.initialFormValues.irregularTask.hoursAtGenerationSite ||
      this.irregularTask.minutesAtGenerationSite !== this.initialFormValues.irregularTask.minutesAtGenerationSite ||
      this.irregularTask.durationAtDisposalSite !== this.initialFormValues.irregularTask.durationAtDisposalSite ||
      this.irregularTask.hoursAtDisposalSite !== this.initialFormValues.irregularTask.hoursAtDisposalSite ||
      this.irregularTask.minutesAtDisposalSite !== this.initialFormValues.irregularTask.minutesAtDisposalSite;

    const isGenerationSiteTaskDirty =
      this.generationSiteTaskItems.length !== this.initialFormValues.generationSiteTasks.length ||
      this.generationSiteTaskItems.some((item, index) => {
        const initialItem = this.initialFormValues.generationSiteTasks[index];
        return (
          item.taskType?.id !== initialItem.taskType?.id ||
          item.wasteTypeId !== initialItem.wasteTypeId ||
          item.containerTypeId !== initialItem.containerTypeId ||
          item.containerNum !== initialItem.containerNum
        );
      });

    const isDurationAtGenerationSiteDirty =
      (this.generationSiteTaskCategory === GenerationSiteTaskCategory.TaskWithContainer &&
        this.generationSiteTaskDuration.durationAtGenerationSite !== this.initialFormValues.durationAtGenerationSite) ||
      (this.generationSiteTaskCategory === GenerationSiteTaskCategory.Irregular &&
        this.irregularTask.durationAtGenerationSite !== this.initialFormValues.durationAtGenerationSite);

    const initialFormDateCollectablePeriodItem = _.first(this.initialFormValues.dateCollectablePeriodItems);
    if (initialFormDateCollectablePeriodItem === undefined) throw new Error('Impossible!');
    const dirtyFlags = new Map<string, boolean>();
    dirtyFlags.set('orderGroupId', this.orderGroupId !== this.initialFormValues.orderGroupId);
    dirtyFlags.set(
      'dateCollectablePeriodItem',
      this.defaultDateCollectablePeriodItem.isDirty(initialFormDateCollectablePeriodItem)
    );
    dirtyFlags.set('clientId', this.clientId !== this.initialFormValues.clientId);
    dirtyFlags.set('generationSiteId', this.generationSiteId !== this.initialFormValues.generationSiteId);
    dirtyFlags.set(
      'generationSiteTaskCategory',
      this.generationSiteTaskCategory !== this.initialFormValues.generationSiteTaskCategory
    );
    dirtyFlags.set('generationSiteTask', isGenerationSiteTaskDirty);
    dirtyFlags.set('irregularTask', isIrregularTaskDirty);
    dirtyFlags.set(
      'assignedDisposalSiteIds',
      _.isEqual(_.sortBy(this.disposalSiteIds), _.sortBy(this.initialFormValues.disposalSiteIds)) === false
    );
    dirtyFlags.set(
      'disposalSiteAssignmentType',
      this.disposalSiteAssignmentType !== this.initialFormValues.disposalSiteAssignmentType
    );

    const sortedOrderAssignedDisposalSites = _.sortBy(
      this.orderAssignedDisposalSites,
      (orderAssignedDisposalSite) => orderAssignedDisposalSite.disposalSiteId
    );

    const sortedInitialOrderAssignedDisposalSites = _.sortBy(
      this.initialFormValues.orderAssignedDisposalSites,
      (orderAssignedDisposalSite) => orderAssignedDisposalSite.disposalSiteId
    );

    const isOrderAssignedDisposalSitesDirty =
      sortedOrderAssignedDisposalSites.length === sortedInitialOrderAssignedDisposalSites.length
        ? sortedOrderAssignedDisposalSites.some((orderAssignedDisposalSites, index) => {
            return (
              orderAssignedDisposalSites.disposalSiteId !==
                sortedInitialOrderAssignedDisposalSites[index].disposalSiteId ||
              orderAssignedDisposalSites.durationAtEntrance !==
                sortedInitialOrderAssignedDisposalSites[index].durationAtEntrance
            );
          })
        : true;

    dirtyFlags.set('orderAssignedDisposalSites', isOrderAssignedDisposalSitesDirty);
    dirtyFlags.set('durationAtGenerationSite', isDurationAtGenerationSiteDirty);
    dirtyFlags.set('note', this.note !== this.initialFormValues.note);
    dirtyFlags.set('driverNum', this.driverNum !== this.initialFormValues.driverNum);
    dirtyFlags.set('carNum', this.carNum !== this.initialFormValues.carNum);
    dirtyFlags.set('avoidHighways', this.avoidHighways !== this.initialFormValues.avoidHighways);
    dirtyFlags.set('driverAssignmentType', this.driverAssignmentType !== this.initialFormValues.driverAssignmentType);

    dirtyFlags.set(
      'isAssignableDriversCandidate',
      this.isAssignableDriversCandidate !== this.initialFormValues.isAssignableDriversCandidate
    );

    // NOTE: AssignableDrivers はそれぞれ Driver / Operator / Helper になり得るため、同じ乗務員でも編集されている可能性があるためそれぞれ分けてチェックしている
    const initialDrivers = _.sortBy(
      getAssignableDriverIdsByDriverType(DriverType.Driver, this.initialFormValues.assignableDrivers)
    );
    const initialOperators = _.sortBy(
      getAssignableDriverIdsByDriverType(DriverType.Operator, this.initialFormValues.assignableDrivers)
    );
    const initialHelpers = _.sortBy(
      getAssignableDriverIdsByDriverType(DriverType.Helper, this.initialFormValues.assignableDrivers)
    );

    const drivers = _.sortBy(getAssignableDriverIdsByDriverType(DriverType.Driver, this.assignableDrivers));
    const operators = _.sortBy(getAssignableDriverIdsByDriverType(DriverType.Operator, this.assignableDrivers));
    const helpers = _.sortBy(getAssignableDriverIdsByDriverType(DriverType.Helper, this.assignableDrivers));

    const isDriversDirty = _.isEqual(drivers, initialDrivers) === false;
    const isOperatorsDirty = _.isEqual(operators, initialOperators) === false;
    const isHelpersDirty = _.isEqual(helpers, initialHelpers) === false;

    dirtyFlags.set('assignableDrivers', isDriversDirty || isOperatorsDirty || isHelpersDirty);

    dirtyFlags.set(
      'assignableCarTypeIds',
      _.isEqual(_.sortBy(this.assignableCarTypeIds), _.sortBy(this.initialFormValues.assignableCarTypeIds)) === false
    );
    dirtyFlags.set('assignedCarId', this.assignedCarId !== this.initialFormValues.assignedCarId);
    dirtyFlags.set(
      'routeCollectionAllowed',
      this.routeCollectionAllowed !== this.initialFormValues.routeCollectionAllowed
    );
    dirtyFlags.set('routingGroup', this.routingGroup !== this.initialFormValues.routingGroup);
    const routingGroupOrderIds = _.sortBy(
      this.routingGroup !== undefined
        ? (this.routingGroup.orderIds.filter((id) => typeof id === 'string') as string[])
        : []
    );
    const initialRoutingGroupOrderIds = _.sortBy(
      this.initialFormValues.routingGroup !== undefined ? this.initialFormValues.routingGroup.orderIds : []
    );
    dirtyFlags.set('routingGroup.orderIds', _.isEqual(routingGroupOrderIds, initialRoutingGroupOrderIds) === false);

    dirtyFlags.set('preloadStatus', this.preloadStatus !== this.initialFormValues.preloadStatus);
    dirtyFlags.set(
      'isFixedArrivalTimeReportNeeded',
      this.isFixedArrivalTimeReportNeeded !== this.initialFormValues.isFixedArrivalTimeReportNeeded
    );
    dirtyFlags.set(
      'marginTypeOfFixedArrivalTime',
      this.marginTypeOfFixedArrivalTime !== this.initialFormValues.marginTypeOfFixedArrivalTime
    );
    dirtyFlags.set(
      'marginOfFixedArrivalTime',
      this.marginOfFixedArrivalTime !== this.initialFormValues.marginOfFixedArrivalTime
    );

    dirtyFlags.set(
      'fixedDisplayOnReservation',
      this.fixedDisplayOnReservation !== this.initialFormValues.fixedDisplayOnReservation
    );
    dirtyFlags.set(
      'fixedDisplayOnReservationName',
      this.fixedDisplayOnReservationName !== this.initialFormValues.fixedDisplayOnReservationName
    );

    const isDirty = Array.from(dirtyFlags.values()).some((value) => value);
    return isDirty;
  }

  /**
   * 到着日時の配列から先頭５つを返す。
   * 用途例: 受注の複数日の一括登録を行う際に、到着日時が６つ以上設定されている場合は先頭５つのみをフォームに表示される。
   */
  get representativeDateCollectablePeriodItems(): DateCollectablePeriodItem[] {
    return this.dateCollectablePeriodItems.slice(
      representativeDateCollectablePeriodFirst,
      representativeDateCollectablePeriodLast
    );
  }

  /**
   * 到着日時の配列の最初の日時を返す。
   */
  get representativeOrderPlanCollectablePeriodItems(): DateCollectablePeriodItem[] {
    return this.orderPlanCollectablePeriodItems.slice(
      representativeOrderPlanCollectablePeriodFirst,
      representativeOrderPlanCollectablePeriodLast
    );
  }

  get defaultDateCollectablePeriodItem(): DateCollectablePeriodItem {
    const dateCollectablePeriodItem = _.first(this.dateCollectablePeriodItems);
    if (dateCollectablePeriodItem === undefined) throw new Error('Impossible!');
    return dateCollectablePeriodItem;
  }

  /**
   * fixedArrivalTime が受注到着日時から外れているかどうか
   */
  get isDateCollectablePeriodOffFromFixedArrivalTime(): boolean {
    if (this.initialFormValues.fixedArrivalTime === undefined) return false;

    // fixedArrivalTime が受注到着日時から外れているかどうかを確認している。
    const dirtyFlags = new Map<string, boolean>();
    const initialFormDateCollectablePeriodItem = _.first(this.initialFormValues.dateCollectablePeriodItems);
    if (initialFormDateCollectablePeriodItem === undefined) throw new Error('Impossible!');
    dirtyFlags.set(
      'dateCollectablePeriodItem.isSameDay',
      !this.defaultDateCollectablePeriodItem.isSameDay(initialFormDateCollectablePeriodItem)
    );
    dirtyFlags.set(
      'dateCollectablePeriodItem.isTimeIncluded',
      !this.defaultDateCollectablePeriodItem.isTimeIncluded(this.initialFormValues.fixedArrivalTime)
    );

    const isDateCollectablePeriodOffFromFixedArrivalTime = Array.from(dirtyFlags.values()).some((value) => value);
    return isDateCollectablePeriodOffFromFixedArrivalTime;
  }

  get isFixedArrivalTimeConfirmNeeded(): boolean {
    const dirtyFlags = new Map<string, boolean>();
    dirtyFlags.set('orderGroupId', this.orderGroupId !== this.initialFormValues.orderGroupId);
    dirtyFlags.set('clientId', this.clientId !== this.initialFormValues.clientId);
    dirtyFlags.set('generationSiteId', this.generationSiteId !== this.initialFormValues.generationSiteId);

    dirtyFlags.set(
      'isDateCollectablePeriodOffFromFixedArrivalTime',
      this.isDateCollectablePeriodOffFromFixedArrivalTime
    );

    dirtyFlags.set(
      'isFixedArrivalTimeReportNeeded',
      this.isFixedArrivalTimeReportNeeded !== this.initialFormValues.isFixedArrivalTimeReportNeeded
    );
    dirtyFlags.set(
      'marginTypeOfFixedArrivalTime',
      this.marginTypeOfFixedArrivalTime !== this.initialFormValues.marginTypeOfFixedArrivalTime
    );
    dirtyFlags.set(
      'marginOfFixedArrivalTime',
      this.marginOfFixedArrivalTime !== this.initialFormValues.marginOfFixedArrivalTime
    );

    const isFixedArrivalTimeConfirmNeeded = Array.from(dirtyFlags.values()).some((value) => value);
    return isFixedArrivalTimeConfirmNeeded;
  }

  getCollectablePeriodTemplateNameFromId(collectablePeriodTemplateId: Maybe<string>): Maybe<string> {
    if (collectablePeriodTemplateId === undefined) return undefined;
    return this.collectablePeriodTemplateMap.get(collectablePeriodTemplateId)?.name;
  }

  onCollectablePeriodTemplateChange(): void {
    this.orderValidation.resetErrorByField('collectablePeriod');
  }

  onChangeCollectableDistinctTime(): void {
    this.orderValidation.resetErrorByField('collectablePeriod');
  }

  onChangeCollectablePeriodStart(): void {
    this.orderValidation.resetErrorByField('collectablePeriod');
  }

  onChangeCollectablePeriodEnd(): void {
    this.orderValidation.resetErrorByField('collectablePeriod');
  }

  /*
   * 到着日時をリセットする。
   * デフォルトの到着時間テンプレートがある場合は、テンプレートで値をリセットする
   */
  resetDateCollectablePeriodItems(defaultCollectablePeriodTemplate?: CollectablePeriodTemplateEntity): void {
    this.dateCollectablePeriodItems = [
      this.getDefaultInitialFormDateCollectablePeriodItem(defaultCollectablePeriodTemplate),
    ];
  }

  resetOrderPlanCollectablePeriodItems(): void {
    this.orderPlanCollectablePeriodItems = [this.getDefaultInitialFormDateCollectablePeriodItem()];
  }

  // オーダーパネルの複数候補日アイテムが確定した時に使う
  setOrderPlanCollectablePeriodItems(editItems: DateCollectablePeriodItem[]): void {
    const items = _.cloneDeep(editItems);
    // 未入力の項目があった場合は削除する

    // 日付順にソートする
    items.sort((a, b) => {
      if (a.date === undefined || b.date === undefined) return 0;
      return a.date.getTime() - b.date.getTime();
    });

    // 日付が重複している場合は削除する
    const uniqItems = _.uniqBy(items, (item) => {
      if (item.date === undefined) return '';
      // Dateまでの制度で比較する
      const date = new Date(item.date.getFullYear(), item.date.getMonth(), item.date.getDate());
      return date.getTime();
    });

    // 暗黙の削除なので元の項目数と異なる場合はエラースナックバー出しておいたりしても良い気がする

    this.orderPlanCollectablePeriodItems = uniqItems;
    // defaultDateCollectablePeriodItem(瞬間チェックの対象日時でdateCollectablePeriodItemsの先頭)を複数候補日の最も若い日付にする
    const firstItem = _.first(uniqItems);
    this.dateCollectablePeriodItems = firstItem !== undefined ? [firstItem] : this.dateCollectablePeriodItems;
  }

  // オーダーパネルの複数候補日アイテムを編集用の配列にセットする
  setEditOrderPlanCollectablePeriodItems(items: DateCollectablePeriodItem[]): void {
    this.editOrderPlanCollectablePeriodItems = items;
  }

  addEditOrderPlanCollectablePeriodItem(item: DateCollectablePeriodItem): void {
    this.editOrderPlanCollectablePeriodItems.push(item);
  }

  deleteEditOrderPlanCollectablePeriodItem(index: number): void {
    this.editOrderPlanCollectablePeriodItems.splice(index, 1);
  }

  // 複数候補日の配列をAPIに投げる用に変換する
  getRepresentativeOrderPlanInput(): OrderPlanInput {
    // 候補日の一括登録の場合、単一のplanが定義されないため、代表値でしかないことに注意する
    // 複数候補日編集ではない場合はdateCollectablePeriodItemsをfixedにセットする
    if (this.dateCollectablePeriodType !== DateCollectablePeriodTypes.Plan) {
      return {
        fixed: {
          date: this.defaultDateCollectablePeriodItem.getDate(),
          collectablePeriodTemplateName: this.defaultDateCollectablePeriodItem.collectablePeriodTemplateName,
          collectablePeriodStart: this.defaultDateCollectablePeriodItem.getCollectablePeriodStartOrDistinctTime(),
          collectablePeriodEnd: this.defaultDateCollectablePeriodItem.getCollectablePeriodEndOrDistinctTime(),
          unloadDate: this.defaultDateCollectablePeriodItem.unloadDate,
        },
      };
    }

    // orderPlanCollectablePeriodItemsを変更した時にorderPlanを再計算する
    const _items = this.orderPlanCollectablePeriodItems.map((item) => {
      return {
        date: item.date,
        collectablePeriodTemplateName: item.collectablePeriodTemplateName,
        collectablePeriodStart: item.getCollectablePeriodStartOrDistinctTime(),
        collectablePeriodEnd: item.getCollectablePeriodEndOrDistinctTime(),
        unloadDate: item.unloadDate,
      };
    });
    const orderPlanItems = _items.filter((item) => item.date !== undefined);

    // 複数候補日が存在しない場合は単数の要素をfixedにセットする
    if (orderPlanItems.length === 0) {
      return {
        fixed: {
          date: this.defaultDateCollectablePeriodItem.getDate(),
          collectablePeriodTemplateName: this.defaultDateCollectablePeriodItem.collectablePeriodTemplateName,
          collectablePeriodStart: this.defaultDateCollectablePeriodItem.getCollectablePeriodStartOrDistinctTime(),
          collectablePeriodEnd: this.defaultDateCollectablePeriodItem.getCollectablePeriodEndOrDistinctTime(),
          unloadDate: this.defaultDateCollectablePeriodItem.unloadDate,
        },
      };
    }
    // 複数候補日の配列をorderPlanにセットする、単数の場合はfixedに、複数の場合はcandidateDatesにセットする
    if (orderPlanItems.length === 1) {
      if (orderPlanItems[0].date === undefined) {
        throw new Error('date is undefined');
      }
      return {
        fixed: {
          // Maybe<Date>からDateへの換装、事前にフィルタリング済み
          date: orderPlanItems[0].date,
          collectablePeriodTemplateName: orderPlanItems[0].collectablePeriodTemplateName,
          collectablePeriodStart: orderPlanItems[0].collectablePeriodStart,
          collectablePeriodEnd: orderPlanItems[0].collectablePeriodEnd,
          unloadDate: orderPlanItems[0].unloadDate,
        },
      };
    }
    return {
      candidateDates: orderPlanItems.map((item) => {
        if (item.date === undefined) {
          throw new Error('date is undefined');
        }

        return {
          date: item.date,
          collectablePeriodTemplateName: item.collectablePeriodTemplateName,
          collectablePeriodStart: item.collectablePeriodStart,
          collectablePeriodEnd: item.collectablePeriodEnd,
          unloadDate: item.unloadDate,
        };
      }),
    };
  }

  getCreateOrderRecurringSettingsInput(): Maybe<CreateOrderRecurringSettingsInput> {
    if (this.recurringOrderSettings === undefined) return undefined;
    return {
      type: this.recurringOrderSettings.type,
      step: this.recurringOrderSettings.step,
      endAt: this.recurringOrderSettings.endAt,
      daysOfWeek: this.recurringOrderSettings.daysOfWeek?.map((day) => indexToDayOfWeek(day)),
      daysOfMonth: this.recurringOrderSettings.daysOfMonth,
      includeNationalHolidays: this.recurringOrderSettings.includeNationalHolidays,
    };
  }

  setPreload(status: PreloadStatus): void {
    this.preloadStatus = status;
    if (status === PreloadStatus.Forced) {
      // 宵積み指定にされた場合、繰り返し設定はクリアする
      this.recurringOrderSettings = undefined;
      this.preloadStatus = PreloadStatus.Forced;
      return;
    }

    if (status === PreloadStatus.NotAllowed || status === PreloadStatus.Modifiable) {
      this.preloadStatus = status;
      this.resetPreloadRelatedErrors();
    }
  }

  /**
   * 受注登録前のチェックのためのデータを取得する
   */
  getValidateOrderData(): IValidateOrderData {
    const dateCollectablePeriodItem = this.defaultDateCollectablePeriodItem;
    const collectablePeriodStart = dateCollectablePeriodItem.getCollectablePeriodStartOrDistinctTime();
    const collectablePeriodEnd = dateCollectablePeriodItem.getCollectablePeriodEndOrDistinctTime();

    const durationAtGenerationSite = this.getDurationAtGenerationSite();
    const generationSiteTasks = this.getCreateGenerationSiteTaskInput();
    const irregularTasks = this.getCreateIrregularTaskInput();
    const validateOrderData: IValidateOrderData = {
      date: dateCollectablePeriodItem.getDate(),
      plan: this.getRepresentativeOrderPlanInput(),
      collectablePeriodTemplateName: dateCollectablePeriodItem.collectablePeriodTemplateName,
      collectablePeriodStart,
      collectablePeriodEnd,
      unloadDate: dateCollectablePeriodItem.unloadDate,
      orderGroupId: this.orderGroupId!,
      generationSiteId: this.generationSiteId!,
      durationAtGenerationSite: durationAtGenerationSite!,
      routeCollectionAllowed: this.routeCollectionAllowed,
      preloadStatus: this.preloadStatus,
      // TODO: 処分場の入退場時間の後続リリースで削除
      assignedDisposalSiteIds: this.disposalSiteIds!,
      // TODO: 処分場の入退場時間の後続リリースで削除
      disposalSiteAssignmentType: this.disposalSiteAssignmentType!,
      assignedDisposalSitesAndType: {
        orderDisposalSites: this.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
          return {
            disposalSiteId: orderAssignedDisposalSite.disposalSiteId,
            durationAtEntrance: orderAssignedDisposalSite.durationAtEntrance,
            priority: orderAssignedDisposalSite.priority,
          };
        }),
        disposalSiteAssignmentType: this.disposalSiteAssignmentType,
      },
      assignableDriversAndNum: {
        driverAssignmentType: this.driverAssignmentType,
        assignableDrivers: this.assignableDrivers,
        minAssignedDriverNum: this.driverNum,
        maxAssignedDriverNum: this.driverNum,
      },
      assignedCarId: this.assignedCarId,
      assignableCarTypeIds: this.assignableCarTypeIds,
      assignedBaseSiteId: undefined,
      minAssignedCarNum: this.carNum,
      maxAssignedCarNum: this.carNum,
      carNum: this.carNum,
      note: this.note,
      noteForAssignedDriver: '',
      avoidHighways: this.avoidHighways,
      fixedArrivalTime: this.fixedArrivalTime,
      isFixedArrivalTimeReportNeeded: this.isFixedArrivalTimeReportNeeded,
      marginTypeOfFixedArrivalTime: this.marginTypeOfFixedArrivalTime,
      marginOfFixedArrivalTime: this.marginOfFixedArrivalTime,
      routingGroup: this.routingGroup
        ? { orderIds: this.routingGroup.orderIds.filter((id) => typeof id === 'string') as string[] }
        : undefined,
      fixedDisplayOnReservation: this.fixedDisplayOnReservation,
      fixedDisplayOnReservationName: this.fixedDisplayOnReservationName,
      schedulingPriority: this.schedulingPriority,
      generationSiteTasks,
      irregularTasks,
      recurringSettings: undefined,
      status: OrderStatus.Active,
    };
    return validateOrderData;
  }

  /**
   * 瞬間チェックのためのデータを取得する
   */
  getOrderAcceptanceCheckData(): IOrderAcceptanceCheckData {
    const dateCollectablePeriodItem = this.defaultDateCollectablePeriodItem;
    const collectablePeriodStart = dateCollectablePeriodItem.getCollectablePeriodStartOrDistinctTime();
    const collectablePeriodEnd = dateCollectablePeriodItem.getCollectablePeriodEndOrDistinctTime();

    const durationAtGenerationSite = this.getDurationAtGenerationSite();
    const generationSiteTasks = this.getCreateGenerationSiteTaskInput();
    const irregularTasks = this.getCreateIrregularTaskInput();

    const orderAcceptanceCheckData: IOrderAcceptanceCheckData = {
      id: this.order?.persistentId,
      date: dateCollectablePeriodItem.getDate(),
      plan: this.getRepresentativeOrderPlanInput(),
      collectablePeriodTemplateName: dateCollectablePeriodItem.collectablePeriodTemplateName,
      collectablePeriodStart,
      collectablePeriodEnd,
      unloadDate: dateCollectablePeriodItem.unloadDate,
      clientId: this.clientId!,
      orderGroupId: this.orderGroupId!,
      generationSiteId: this.generationSiteId!,
      durationAtGenerationSite: durationAtGenerationSite!,
      routeCollectionAllowed: this.routeCollectionAllowed,
      preloadStatus: this.preloadStatus,
      // TODO: 処分場の入退場時間の後続リリースで削除
      assignedDisposalSiteIds: this.disposalSiteIds!,
      // TODO: 処分場の入退場時間の後続リリースで削除
      disposalSiteAssignmentType: this.disposalSiteAssignmentType!,
      assignedDisposalSitesAndType: {
        orderDisposalSites: this.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
          return {
            disposalSiteId: orderAssignedDisposalSite.disposalSiteId,
            durationAtEntrance: orderAssignedDisposalSite.durationAtEntrance,
            priority: orderAssignedDisposalSite.priority,
          };
        }),
        disposalSiteAssignmentType: this.disposalSiteAssignmentType,
      },
      assignableDriversAndNum: {
        minAssignedDriverNum: this.driverNum,
        maxAssignedDriverNum: this.driverNum,
        driverAssignmentType: this.driverAssignmentType,
        assignableDrivers: this.assignableDrivers,
      },
      assignedCarId: this.assignedCarId,
      assignedBaseSiteId: undefined,
      assignableCarTypeIds: this.assignableCarTypeIds,
      minAssignedCarNum: this.carNum,
      maxAssignedCarNum: this.carNum,
      carNum: this.carNum,
      note: this.note,
      noteForAssignedDriver: '',
      avoidHighways: this.avoidHighways,
      fixedArrivalTime: this.fixedArrivalTime,
      isFixedArrivalTimeReportNeeded: this.isFixedArrivalTimeReportNeeded,
      marginTypeOfFixedArrivalTime: this.marginTypeOfFixedArrivalTime,
      marginOfFixedArrivalTime: this.marginOfFixedArrivalTime,
      routingGroup: this.routingGroup
        ? { orderIds: this.routingGroup.orderIds.filter((id) => typeof id === 'string') as string[] }
        : undefined,
      fixedDisplayOnReservation: this.fixedDisplayOnReservation,
      fixedDisplayOnReservationName: this.fixedDisplayOnReservationName,
      schedulingPriority: this.schedulingPriority,
      generationSiteTasks,
      irregularTasks,
      includeFollowingRecurringOrders: undefined,
      status: OrderStatus.Active,
      reservationId: undefined,
    };
    return orderAcceptanceCheckData;
  }

  private resetPreloadRelatedErrors(): void {
    this.orderValidation.resetErrorByReason(InfeasibilityReasons.PreloadTaskMustNotBeAllocate);
    this.orderValidation.resetErrorByReason(InfeasibilityReasons.PreloadTaskMustBeFetch);
    this.orderValidation.resetErrorByReason(InfeasibilityReasons.PreloadOrderMustHaveAssignedDisposalSite);
  }

  getDefaultInitialFormDateCollectablePeriodItem(
    defaultCollectablePeriodTemplate?: CollectablePeriodTemplateEntity
  ): DateCollectablePeriodItem {
    const dateCollectablePeriodItem: DateCollectablePeriodItem = new DateCollectablePeriodItem(
      this.getInitialDate(),
      defaultCollectablePeriodTemplate?.name,
      defaultCollectablePeriodTemplate?.collectablePeriodStart,
      defaultCollectablePeriodTemplate?.collectablePeriodEnd,
      undefined,
      undefined,
      defaultCollectablePeriodTemplate?.id
    );
    return dateCollectablePeriodItem;
  }

  /**
   * フォームに入力されている値を返す。
   */
  getFormValues(): IFormValues {
    return {
      orderGroupId: this.orderGroupId,
      dateCollectablePeriodType: this.dateCollectablePeriodType,
      dateCollectablePeriodItems: this.dateCollectablePeriodItems,
      orderPlanCollectablePeriodItems: this.orderPlanCollectablePeriodItems,
      editOrderPlanCollectablePeriodItems: this.editOrderPlanCollectablePeriodItems,
      clientId: this.clientId,
      generationSiteId: this.generationSiteId,
      generationSiteTaskCategory: this.generationSiteTaskCategory,
      irregularTask: this.irregularTask.clone(),
      carNum: this.carNum,
      disposalSiteIds: _.clone(this.disposalSiteIds),
      disposalSiteAssignmentType: this.disposalSiteAssignmentType,
      orderAssignedDisposalSites: this.orderAssignedDisposalSites,
      generationSiteTasks: this.generationSiteTaskItems.map((item) => item.clone()),
      durationAtGenerationSite: this.getDurationAtGenerationSite(),
      note: this.note,
      attachmentsToAdd: this.attachmentsToAdd,
      attachmentsToRemove: this.attachmentsToRemove,
      driverNum: this.driverNum,
      avoidHighways: this.avoidHighways,
      driverAssignmentType: this.driverAssignmentType,
      isAssignableDriversCandidate: this.isAssignableDriversCandidate,
      assignableDrivers: this.assignableDrivers,
      assignableCarTypeIds: _.clone(this.assignableCarTypeIds),
      assignedCarId: this.assignedCarId,
      routeCollectionAllowed: this.routeCollectionAllowed,
      routingGroup: this.routingGroup,
      fixedDisplayOnReservation: this.fixedDisplayOnReservation,
      fixedDisplayOnReservationName: this.fixedDisplayOnReservationName,
      schedulingPriority: this.schedulingPriority,
      preloadStatus: this.preloadStatus,
      fixedArrivalTime: this.fixedArrivalTime,
      isFixedArrivalTimeReportNeeded: this.isFixedArrivalTimeReportNeeded,
      marginTypeOfFixedArrivalTime: this.marginTypeOfFixedArrivalTime,
      marginOfFixedArrivalTime: this.marginOfFixedArrivalTime,
      recurringSettings: this.recurringOrderSettings?.clone(),
      createdBy: this.createdBy,
      createdAt: this.createdAt,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt,
      erpOrderForm: this.erpOrderForm,
    };
  }

  /**
   * フォームの各プロパティに値を設定をし、整合性をチェックと依存性を持つプロパティの値を調整する。
   * @param initialFormValues フォームに設定する各プロパティの値。
   * @param client 会社を設定する際に、該当の会社のエンティティ。
   * @param generationSite 排出場を設定する際に、該当の排出場のエンティティ。
   */
  initializeFormValues(
    initialFormValues: IFormValues,
    client: Maybe<ClientEntity>,
    generationSite: Maybe<GenerationSiteEntity>
  ) {
    this.generationSiteTaskDuration = new ContainerTypeTaskDuration(this.containerTypes, this.containerTypeTaskTypes);
    this.selectedDisposalSites = [];
    this.orderAssignedDisposalSites = [];
    this.client = client;
    this.generationSite = generationSite;
    this.setFormValues(initialFormValues);

    // フォームに値が設定されているにも関わらず preloaded でない場合は何かが間違っているので落としておく
    if (this.clientId !== undefined && client === undefined) {
      throw new Error(`clientId(${this.clientId}) is set, but pre-loaded client was not found!`);
    }
    if (this.generationSiteId !== undefined && generationSite === undefined) {
      throw new Error(
        `generationSiteId(${this.generationSiteId}) is set, but pre-loaded generationSite was not found!`
      );
    }

    // 会社や排出場が削除されていたらそれは空にする
    // ここで値をクリアする事が正しいのかという事について考えたが、Composite な Entity の値をクリアすると
    // dirty になる事になってしまい、いつコミットされるのか分からない中途半端なデータができあがる事になって
    // しまうので、そうなるくらいであればパネルの中でクリアした方がマシという理由でここでクリアする事にした。
    // この値が undefined になる事によってフォームが valid でなくなるが、バリデーションはフォームが valid
    // な時だけ走る様にしてあるので問題ない。
    if (this.clientId !== undefined && this.client !== undefined && this.client.isDeleted) {
      this.clientId = undefined;
      this.client = undefined;
    }
    if (this.generationSiteId !== undefined && this.generationSite !== undefined && this.generationSite.isDeleted) {
      this.generationSiteId = undefined;
      this.generationSite = undefined;
    }

    // 指定車種の orderGroupId と オーダーの orderGroupId が別だった場合、グループと車種が矛盾する事になるので
    // assignableCarTypeIds から違うものは消しておく
    this.assignableCarTypeIds = this.assignableCarTypeIds.filter((id) => {
      return this.carTypeMap.getOrError(id).orderGroupId === this.orderGroupId;
    });

    // 指定車番から推測した車種の orderGroupId とオーダーの orderGroupId が別だった場合、グループと車番が矛盾する事に
    // なるので assignedCarId は空にする
    if (
      this.assignedCarId !== undefined &&
      this.carTypeMap.getOrError(this.carMap.getOrError(this.assignedCarId).carType.id).orderGroupId !==
        this.orderGroupId
    ) {
      this.assignedCarId = undefined;
    }

    // erpOrderForm
    this.erpOrderForm = initialFormValues.erpOrderForm;

    // ここで on〜 を呼ぶのが気持ち悪い様な気もするが、変更したと言えばしたのでよしとする
    this.onChangeOrderGroup(true);
    this.onClientChange(true);
    this.onGenerationSiteTaskChange(true, this.generationSiteTaskItems);
    this.onChangeSkipDisposalSite(true);
    this.onCarNumChange(this.carNum);
    this.onChangeAssignableCarTypes(true);
    this.onChangeAssignedCar(true);
    this.onChangeGenerationSiteTaskCategory(true);
    this.onChangeAssignedDisposalSite(true);
  }

  // 複数候補日としての情報をリセットする
  resetOrderPlan() {
    if (this.orderPlanCollectablePeriodItems.length === 0) return;
    const firstItem = this.orderPlanCollectablePeriodItems[0];
    this.dateCollectablePeriodItems = firstItem !== undefined ? [firstItem] : this.dateCollectablePeriodItems;
    this.orderPlanCollectablePeriodItems = [];
  }

  private setFormValues(formValues: IFormValues): void {
    this.orderGroupId = formValues.orderGroupId;
    this.dateCollectablePeriodType = formValues.dateCollectablePeriodType;
    this.dateCollectablePeriodItems = _.cloneDeep(formValues.dateCollectablePeriodItems);
    this.orderPlanCollectablePeriodItems = _.cloneDeep(formValues.orderPlanCollectablePeriodItems);
    this.editOrderPlanCollectablePeriodItems = _.cloneDeep(formValues.editOrderPlanCollectablePeriodItems);
    this.clientId = formValues.clientId;
    this.generationSiteId = formValues.generationSiteId;
    this.generationSiteTaskCategory = formValues.generationSiteTaskCategory;
    this.irregularTask = formValues.irregularTask.clone();
    this.carNum = formValues.carNum;
    this.disposalSiteIds = formValues.disposalSiteIds;
    this.disposalSiteAssignmentType = formValues.disposalSiteAssignmentType;
    this.orderAssignedDisposalSites = formValues.orderAssignedDisposalSites;
    this.generationSiteTaskItems = formValues.generationSiteTasks.map((item) => item.clone());
    this.generationSiteTaskDuration.setDefaultDurationAtEntrance(this.generationSite?.defaultDurationAtEntrance ?? 0);
    this.generationSiteTaskDuration.setDurationAtGenerationSite(formValues.durationAtGenerationSite);
    this.note = formValues.note;
    this.driverNum = formValues.driverNum;
    this.avoidHighways = formValues.avoidHighways;
    this.driverAssignmentType = formValues.driverAssignmentType;
    this.isAssignableDriversCandidate = formValues.isAssignableDriversCandidate;
    this.assignableDrivers = formValues.assignableDrivers;
    this.assignableCarTypeIds = formValues.assignableCarTypeIds;
    this.assignedCarId = formValues.assignedCarId;
    this.routeCollectionAllowed = formValues.routeCollectionAllowed;
    this.preloadStatus = formValues.preloadStatus;
    this.fixedArrivalTime = formValues.fixedArrivalTime;
    this.isFixedArrivalTimeReportNeeded = formValues.isFixedArrivalTimeReportNeeded;
    this.marginTypeOfFixedArrivalTime = formValues.marginTypeOfFixedArrivalTime;
    const [marginOfFixedArrivalTimeHours, marginOfFixedArrivalTimeMinutes] = getHoursAndMinutesOf(
      formValues.marginOfFixedArrivalTime
    );
    this.marginOfFixedArrivalTimeHours = marginOfFixedArrivalTimeHours;
    this.marginOfFixedArrivalTimeMinutes = marginOfFixedArrivalTimeMinutes;

    this.routingGroup = formValues.routingGroup;
    this.fixedDisplayOnReservation = formValues.fixedDisplayOnReservation;
    this.fixedDisplayOnReservationName = formValues.fixedDisplayOnReservationName;

    this.schedulingPriority = formValues.schedulingPriority;

    this.defaultDateCollectablePeriodItem.collectablePeriodTemplateId = this.getCollectablePeriodTemplateId(
      formValues.dateCollectablePeriodItems[0].collectablePeriodTemplateName
    );
    this.defaultDateCollectablePeriodItem.setCollectableDistinctTime(
      formValues.dateCollectablePeriodItems[0]?.collectableDistinctTime
    );

    // collectablePeriodTemplateIdを設定する
    this.orderPlanCollectablePeriodItems.forEach((item) => {
      item.collectablePeriodTemplateId = this.getCollectablePeriodTemplateId(item.collectablePeriodTemplateName);
      item.setCollectableDistinctTime();
    });
    this.editOrderPlanCollectablePeriodItems.forEach((item) => {
      item.collectablePeriodTemplateId = this.getCollectablePeriodTemplateId(item.collectablePeriodTemplateName);
      item.setCollectableDistinctTime();
    });

    this.recurringOrderSettings = formValues.recurringSettings;
    if (this.recurringOrderSettings) this.dateCollectablePeriodType = DateCollectablePeriodTypes.Recurring;
    // 複数候補日を持っている場合は複数候補日の設定を優先する
    if (this.dateCollectablePeriodItems.length > 1) {
      this.dateCollectablePeriodType = DateCollectablePeriodTypes.Plan;
      // 複数候補日を持っている場合にもし繰り返し受注を持っていた場合は消す
      this.recurringOrderSettings = undefined;
    }

    this.erpOrderForm = formValues.erpOrderForm;
    this.createdBy = formValues.createdBy;
    this.createdAt = formValues.createdAt;
    this.updatedBy = formValues.updatedBy;
    this.updatedAt = formValues.updatedAt;
  }

  private getDefaultInitialFormValues(transportationClients?: IErpClientData[]): IFormValues {
    const dateCollectablePeriodItem: DateCollectablePeriodItem = this.getDefaultInitialFormDateCollectablePeriodItem();
    const orderPlanCollectablePeriodItems: DateCollectablePeriodItem[] = [];
    const editOrderPlanCollectablePeriodItems: DateCollectablePeriodItem[] = [
      this.getDefaultInitialFormDateCollectablePeriodItem(),
    ];

    let erpOrderForm;
    if (this.canUseErp) {
      ensure(transportationClients);
      erpOrderForm = getDefaultInitialErpOrderFormValues(transportationClients);
    }

    return {
      orderGroupId: this.orderGroups.length === 1 ? this.orderGroups[0].persistentId : undefined,
      dateCollectablePeriodType: DateCollectablePeriodTypes.Default,
      dateCollectablePeriodItems: [dateCollectablePeriodItem],
      orderPlanCollectablePeriodItems,
      editOrderPlanCollectablePeriodItems,
      clientId: undefined,
      generationSiteId: undefined,
      generationSiteTaskCategory: GenerationSiteTaskCategory.TaskWithContainer,
      irregularTask: IrregularTaskItemFactory.instantiateDefault(),
      carNum: 1,
      disposalSiteIds: [],
      disposalSiteAssignmentType: OrderDisposalSiteAssignmentType.NonSequentialMultiple,
      orderAssignedDisposalSites: [],
      generationSiteTasks: [ContainerTypeTaskItemFactory.instantiateDefault()],
      durationAtGenerationSite: undefined,
      note: undefined,
      attachmentsToAdd: [],
      attachmentsToRemove: [],
      driverNum: 1,
      avoidHighways: false,
      driverAssignmentType: DriverAssignmentType.NotDistinguished,
      isAssignableDriversCandidate: false,
      assignableDrivers: [],
      assignableCarTypeIds: [],
      assignedCarId: undefined,
      routeCollectionAllowed: true,
      routingGroup: undefined,
      fixedDisplayOnReservation: this.fixedDisplayOnReservation,
      fixedDisplayOnReservationName: this.fixedDisplayOnReservationName,
      schedulingPriority: OrderSchedulingPriority.None,
      preloadStatus: PreloadStatus.NotAllowed,
      fixedArrivalTime: undefined,
      isFixedArrivalTimeReportNeeded: false,
      marginTypeOfFixedArrivalTime: MarginType.AllowBeforeAndAfter,
      marginOfFixedArrivalTime: 1800,
      recurringSettings: undefined,
      createdBy: undefined,
      createdAt: undefined,
      updatedBy: undefined,
      updatedAt: undefined,
      erpOrderForm,
    };
  }

  private getInitialFormValues(transportationClients?: IErpClientData[]): IFormValues {
    if (this.order) {
      return getInitialFormValuesByOrder(this.order, this.canUseErp, this.erpOrder);
    } else {
      return this.getDefaultInitialFormValues(transportationClients);
    }
  }

  /**
   * 開いた時に最初に選択されている日を返す
   * 仕様上、翌日という事になっている
   * @private
   */
  private getInitialDate(): Date {
    const { userSetting } = useRinLocalStorage();
    // FIXME: localStorage のデータは userSetting.value のように取得するが、typescript が認識している型に value は存在しないので ts-ignore しないと型エラーになってしまう。
    // @ts-ignore
    const scheduleDate = userSetting.value.scheduleDate;

    // NOTE: localStorage に scheduleDate が存在する場合はその日付を返す
    if (scheduleDate) {
      const date = new Date(scheduleDate);
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    const date = new Date();
    // 不要な日付以下(時間、分、秒)を切り落とすため
    return addDays(new Date(date.getFullYear(), date.getMonth(), date.getDate()), 1);
  }

  /**
   * テンプレート名からテンプレ ID を復活する
   * @param name
   * @private
   */
  private getCollectablePeriodTemplateId(name: Maybe<string>): Maybe<PersistentId> {
    const template = _.first(this.collectablePeriodTemplates.filter((template) => template.name === name));
    return template?.persistentId;
  }
}

type DataType = AsyncDataType & {
  /**
   * このパネルを表示したい時に true
   */
  isActive: boolean;
  isCloseConfirmDialogActive: boolean;
  isDeleteRoutingGroupConfirmActive: boolean;
  isDeleteConfirmDialogActive: boolean;
  isDeleteOrderViaReservationConfirmDialogActive: boolean;
  isCancelOrderViaReservationConfirmDialogActive: boolean;
  isPreloadConfirmDialogActive: boolean;
  isRecurringOrderConfirmDialogActive: boolean;
  isDateCollectablePeriodTypeChangeConfirmDialogActive: boolean;
  isFixedArrivalTimeResetConfirmDialogActive: boolean;
  isLessThanDurationOfTasksMessageActive: boolean;
  orderAcceptanceCheckDialogValue: boolean;
  confirmDeleteOrder: () => void;
  confirmCancelViaReservation: (value: Maybe<boolean>) => void;
  confirmDeleteViaReservation: (value: Maybe<boolean>) => void;
  confirmDeleteRoutingGroup: () => void;
  DisabledReason: typeof DisabledReason;
  FormMode: typeof FormMode;
  EnteredGenerationSiteNameState: typeof EnteredGenerationSiteNameState;
  OrderStatus: typeof OrderStatus;
  GenerationSiteTaskCategory: typeof GenerationSiteTaskCategory;
  OrderSchedulingPriority: typeof OrderSchedulingPriority;
  masters: Maybe<Masters>;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  preloadConfirmDialogResolver: Maybe<(value: boolean) => void>;
  dateCollectablePeriodTypeChangeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  recurringOrderConfirmDialogResolver: Maybe<(value: boolean) => void>;
  fixedArrivalTimeResetConfirmDialogResolver: Maybe<(value: boolean) => void>;
  recurringOrderSettingsDialogValue: Maybe<RecurringOrderSettingsDialogValue>;
  businessDaysService: Maybe<IBusinessDaysService>;
  PreloadStatus: typeof PreloadStatus;
  defaultCollectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity>;
  orderApplicationService: IOrderApplicationService;
  driverApplicationService: DriverApplicationService;
  DateCollectablePeriodTypes: typeof DateCollectablePeriodTypes;
  collectablePeriodRegisterDialogValue: boolean;
  OrderDisposalSiteAssignmentType: typeof OrderDisposalSiteAssignmentType;
  dialogOrder: Maybe<IOrderEntity>;
  erpOrderApplicationService: ErpOrderApplicationService;
  isConfirmDeleteErpOrderDialogActive: boolean;
  confirmDeleteErpOrder: () => void;
};

type AsyncDataType = {
  viewModel: Maybe<OrderForm>;
  isOrderAcceptanceCheckAvailable: boolean;
};

export type Masters = {
  officeSetting: OfficeSettingEntity;
  client: Maybe<ClientEntity>;
  generationSite: Maybe<GenerationSiteEntity>;
  orderGroups: OrderGroupEntity[];
  disposalSites: IDisposalSiteEntity[];
  wasteTypes: WasteTypeEntity[];
  containerTypes: ContainerTypeEntity[];
  containerTypeTaskTypes: ContainerTypeTaskTypeEntity[];
  collectablePeriodTemplates: CollectablePeriodTemplateEntity[];
  drivers: DriverEntity[];
  carTypes: AggregatedCarTypeEntity[];
  cars: ICarEntity[];
  assignableDriverAttendances: Maybe<DriverAttendanceEntity[]>;
  holidayRules: HolidayRuleEntity;
  packingStyles: PackingStyleEntity[];
  taskTypes: TaskTypeEntity[];
  transportationClients: Maybe<IErpClientData[]>;
};

// 続けて登録する時に初期化する
type InitialMaster = {
  client: Maybe<ClientEntity>;
  generationSite: Maybe<GenerationSiteEntity>;
};

// フォームに必要なマスタをロードするために必要な値
interface IPreloadableFormObject {
  assignableDrivers: IOrderAssignableDriver[];
  date: Maybe<Date>;
  clientId: Maybe<PersistentId>;
  generationSiteId: Maybe<PersistentId>;
}

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'ROrderForm',
  components: {
    ROrderGenerationSiteForm,
    RDateCollectablePeriodTypeInput,
    RDateCollectablePeriodBatchRegisterDialog,
    ROrderAcceptanceCheckDialog,
    RRecurringOrderSettingsDialog,
    RRecurringOrderRegisterDialog,
    RRecurringOrderDeleteDialog,
    RDateCollectablePeriodInput,
    RDisposalSites,
    RDriverAssignment,
    RTaskTypeSelect,
    RGenerationSiteOptions,
    RScheduleOrderViaReservationCancelConfirmDialog,
    RScheduleOrderViaReservationDeleteConfirmDialog,
    RPlanCandidateDatesDeleteDialog,
    RRecordStamperForForm,
    RUploadedFile,
    RRouteCollectionInput,
    RErpOrderForm,
  },
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const orderApplicationService = this.$context.applications.get(orderSymbol);
    const driverApplicationService = this.$context.applications.get(driverSymbol);
    const erpOrderApplicationService = this.$context.applications.get(erpOrderApplicationServiceSymbol);

    return {
      isActive: false,
      isCloseConfirmDialogActive: false,
      isDeleteRoutingGroupConfirmActive: false,
      isDeleteConfirmDialogActive: false,
      isDeleteOrderViaReservationConfirmDialogActive: false,
      isCancelOrderViaReservationConfirmDialogActive: false,
      isPreloadConfirmDialogActive: false,
      isRecurringOrderConfirmDialogActive: false,
      isDateCollectablePeriodTypeChangeConfirmDialogActive: false,
      isFixedArrivalTimeResetConfirmDialogActive: false,
      isLessThanDurationOfTasksMessageActive: false,
      orderAcceptanceCheckDialogValue: false,
      confirmDeleteOrder: () => {},
      confirmDeleteRoutingGroup: () => {},
      confirmCancelViaReservation: () => {},
      confirmDeleteViaReservation: () => {},
      viewModel: undefined as Maybe<OrderForm>,
      DisabledReason,
      FormMode,
      EnteredGenerationSiteNameState,
      OrderStatus,
      GenerationSiteTaskCategory,
      OrderSchedulingPriority,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      preloadConfirmDialogResolver: undefined,
      recurringOrderConfirmDialogResolver: undefined,
      dateCollectablePeriodTypeChangeConfirmDialogResolver: undefined,
      fixedArrivalTimeResetConfirmDialogResolver: undefined,
      masters: undefined,
      isOrderAcceptanceCheckAvailable: false,
      recurringOrderSettingsDialogValue: undefined,
      businessDaysService: undefined,
      defaultCollectablePeriodTemplate: undefined,
      PreloadStatus,
      orderApplicationService,
      driverApplicationService,
      DateCollectablePeriodTypes,
      collectablePeriodRegisterDialogValue: false,
      OrderDisposalSiteAssignmentType,
      dialogOrder: undefined,
      erpOrderApplicationService,
      isConfirmDeleteErpOrderDialogActive: false,
      confirmDeleteErpOrder: () => {},
    };
  },
  computed: {
    showRegisteredNotes(): boolean {
      if (!this.viewModel) return false;

      return !!(
        (this.viewModel.client && this.viewModel.client.note) ||
        (this.viewModel.generationSite && this.viewModel.generationSite.note) ||
        (this.viewModel.generationSite && this.viewModel.generationSite.noteForOffice) ||
        (this.viewModel.generationSite && this.viewModel.generationSite.attachments.length > 0) ||
        this.showNoteFromReservation ||
        this.viewModel.selectedDisposalSites.some((disposalSite) => {
          return disposalSite.note !== undefined && disposalSite.note !== '';
        })
      );
    },
    showNoteFromReservation(): boolean {
      if (!this.viewModel) return false;

      return (
        this.viewModel.order !== undefined &&
        this.viewModel.order.noteFromReservation !== undefined &&
        this.viewModel.order.noteFromReservation !== ''
      );
    },
    recurringIntervalText(): Maybe<string> {
      return this.viewModel?.recurringOrderSettings?.getRecurringIntervalText({
        sort: this.viewModel.formMode === FormMode.Edit,
      });
    },
    hasMultipleCarNum(): boolean {
      if (this.viewModel === undefined) return false;
      // NOTE: 一括登録時に車台数が個別に設定されているので、1件でも複数車になっていかをチェックする
      if (this.viewModel.dateCollectablePeriodType === DateCollectablePeriodTypes.BatchRegister) {
        return (
          this.viewModel.dateCollectablePeriodItems.some((item) => item.carNum && item.carNum > 1) ||
          this.viewModel.carNum > 1
        );
      }
      if (this.viewModel.carNum > 1) return true;
      return false;
    },
    editScheduleDate(): Maybe<Date> {
      if (this.viewModel === undefined) return undefined;
      if (this.viewModel.order === undefined) return undefined;
      if (this.viewModel.order.editScheduleDate === undefined) return undefined;
      return this.viewModel.order.editScheduleDate;
    },
    // NOTE: 排出場作業で選択された荷姿を処分する作業合計時間
    // 全ての処分場に対して同じ作業時間とする
    disposalSiteTotalDurationOfTasks(): number {
      if (this.viewModel === undefined) return 0;
      if (this.masters === undefined) return 0;

      const masters = this.masters;

      // NOTE: 処分場作業の合計
      let totalDurationOfTask = 0;

      const containerTypeAndDurations: {
        containerTypeId: string;
        containerNum: number;
      }[] = [];

      // NOTE: 作業時間の合計を計算するために containerTypeId と containerNum を持つ array を作る
      this.viewModel.generationSiteTaskItems.forEach((item) => {
        if (item.containerTypeId === undefined || item.containerNum === undefined) {
          return;
        }

        // NOTE: 同じ containerid がすでに存在するか確認する
        const findIndex = containerTypeAndDurations.findIndex(
          (containerTypeAndDuration) => containerTypeAndDuration.containerTypeId === item.containerTypeId
        );

        // NOTE: 同じ containerId がない場合は追加する
        if (findIndex === -1) {
          containerTypeAndDurations.push({
            containerTypeId: item.containerTypeId,
            containerNum: item.containerNum,
          });

          return;
        }
        const containerNum = containerTypeAndDurations[findIndex].containerNum + item.containerNum;

        // NOTE: 同じ containerId がある場合は要素を追加せず、 containerNum を加算する
        containerTypeAndDurations[findIndex] = {
          containerTypeId: item.containerTypeId,
          containerNum,
        };
      });

      // NOTE: containerTypeId と taskType が '処分' である containerTypeTaskType を取得する
      // SiteType で判定すると今後処分場作業の種類が増えた場合に対応できないが、 '処分' 作業の taskTypeId を定数で埋め込む必要があるので暫定的に SiteType で判定する
      containerTypeAndDurations.forEach((containerTypeAndDuration) => {
        if (
          containerTypeAndDuration.containerTypeId === undefined ||
          containerTypeAndDuration.containerNum === undefined
        ) {
          return;
        }
        const containerTypeTaskType = masters.containerTypeTaskTypes.find(
          (containerTypeTaskType) =>
            containerTypeTaskType.containerTypeId === containerTypeAndDuration.containerTypeId &&
            containerTypeTaskType.taskType.baseTaskType.siteType === SiteType.DisposalSite
        );
        if (containerTypeTaskType === undefined) {
          return;
        }
        // NOTE: 数で時間増する場合は containerNum の個数だけ乗算する
        if (containerTypeTaskType.isProportionalToCount.value) {
          totalDurationOfTask += containerTypeTaskType.duration.value * containerTypeAndDuration.containerNum;
        } else {
          totalDurationOfTask += containerTypeTaskType.duration.value;
        }
      });

      return totalDurationOfTask;
    },
    preloadStatusText(): string {
      if (!this.viewModel) return '';
      switch (this.viewModel.preloadStatus) {
        case PreloadStatus.Forced:
          return '宵積みを指定しているため、翌営業日以降に搬入を行います。';
        case PreloadStatus.Modifiable:
          return '受注が入りきらなかった場合、宵積みに指定する候補として表示されます。';
        default:
          return '';
      }
    },
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.ORDER;
    },
    isOnlineReservationEnabled(): boolean {
      return this.masters?.officeSetting.isOnlineReservationEnabled ?? false;
    },
    isSchedulingPriorityDisabled(): boolean {
      return this.viewModel === undefined || this.viewModel.generationSiteId === undefined;
    },
    isBatchRegisterCarNumDisabled(): boolean {
      if (this.viewModel === undefined) return true;
      if (this.viewModel.canUseErp) return true;
      if (this.viewModel.routingGroup !== undefined) return true;
      if (this.viewModel.isFixedArrivalTimeReportNeeded) return true;

      return false;
    },
    batchRegisterCarNumDisabledReason(): string {
      if (this.viewModel === undefined) return '';
      if (this.viewModel.canUseErp) return '稼ぎ頭連携されている場合は車台数を変更できません';
      if (this.viewModel.routingGroup !== undefined) return 'ルート回収の指定がある場合は、車台数を複数にできません';
      if (this.viewModel.isFixedArrivalTimeReportNeeded)
        return '到着予定時刻の報告をする場合は車台数を複数にできません';

      return '';
    },
    isCarNumDisabled(): boolean {
      if (this.viewModel === undefined) return true;
      if (this.viewModel.generationSiteId === undefined) return true;
      if (this.viewModel.orderGroupId === undefined) return true;
      if (this.viewModel.canUseErp) return true;
      if (this.viewModel.routingGroup !== undefined) return true;
      if (this.viewModel.isFixedArrivalTimeReportNeeded) return true;
      if (this.viewModel.dateCollectablePeriodType === DateCollectablePeriodTypes.BatchRegister) return true;

      return false;
    },
    carNumDisabledReason(): string {
      if (this.viewModel === undefined) return '';
      if (this.viewModel.generationSiteId === undefined) return '排出場を選択してください';
      if (this.viewModel.orderGroupId === undefined) return '担当グループを選択してください';
      if (this.viewModel.canUseErp) return '稼ぎ頭連携されている場合は車台数を変更できません';
      if (this.viewModel.routingGroup !== undefined) return 'ルート回収の指定がある場合は、車台数を複数にできません';
      if (this.viewModel.isFixedArrivalTimeReportNeeded)
        return '到着予定時刻の報告をする場合は車台数を複数にできません';
      if (this.viewModel.dateCollectablePeriodType === DateCollectablePeriodTypes.BatchRegister)
        return '複数受注の一括登録時は到着日時の詳細設定から個別に設定できます';

      return '';
    },
    isAssignedCarDisabled(): boolean {
      if (this.viewModel === undefined) return true;
      if (this.viewModel.generationSiteId === undefined) return true;
      if (this.viewModel.orderGroupId === undefined) return true;
      if (this.viewModel.assignableCarTypeIds.length > 0) return true;

      return false;
    },
    assignedCarDisabledReason(): string {
      if (!this.viewModel) return '';

      if (this.viewModel.generationSiteId === undefined) {
        return '排出場を選択してください';
      }
      if (this.viewModel.orderGroupId === undefined) {
        return '担当グループを選択してください';
      }
      if (this.viewModel.assignableCarTypeIds.length > 0) {
        return '車種か車番どちらかしか選択できません';
      }

      return '';
    },
    isAssignableCarTypesDisabled(): boolean {
      if (this.viewModel === undefined) return true;
      if (this.viewModel.generationSiteId === undefined) return true;
      if (this.viewModel.orderGroupId === undefined) return true;
      if (this.viewModel.assignedCarId !== undefined) return true;

      return false;
    },
    assignableCarTypeDisabledReason(): string {
      if (!this.viewModel) return '';

      if (this.viewModel.generationSiteId === undefined) {
        return '排出場を選択してください';
      }
      if (this.viewModel.orderGroupId === undefined) {
        return '担当グループを選択してください';
      }
      if (this.viewModel.assignedCarId !== undefined) {
        return '車種か車番どちらかしか選択できません';
      }

      return '';
    },
    isDriverNumDisabled(): boolean {
      if (this.viewModel === undefined) return true;
      if (this.viewModel.generationSiteId === undefined) return true;
      if (this.viewModel.orderGroupId === undefined) return true;
      if (!this.isCarNumOfDateCollectablePeriodItemsSameValue(this.viewModel.dateCollectablePeriodItems)) return true;

      return false;
    },
    isDriverAssignmentDisabled(): boolean {
      if (this.viewModel === undefined) return true;
      if (this.viewModel.generationSiteId === undefined) return true;
      if (this.viewModel.orderGroupId === undefined) return true;
      if (!this.isCarNumOfDateCollectablePeriodItemsSameValue(this.viewModel.dateCollectablePeriodItems)) return true;

      return false;
    },
    driverAssignmentDisabledReason(): string {
      if (!this.viewModel) return '';

      if (this.viewModel.generationSiteId === undefined) {
        return '排出場を選択してください';
      }
      if (this.viewModel.orderGroupId === undefined) {
        return '担当グループを選択してください';
      }
      if (!this.isCarNumOfDateCollectablePeriodItemsSameValue(this.viewModel.dateCollectablePeriodItems)) {
        return '複数の車で一括登録する場合は設定できません';
      }

      return '';
    },
    isFixedDisplayOnReservationDisabled(): boolean {
      if (this.viewModel === undefined) return true;
      if (this.viewModel.generationSiteId === undefined) return true;
      if (this.isBatchRegister) return true;
      return false;
    },
    fixedDisplayOnReservationDisabledReason(): string {
      if (this.viewModel === undefined) return '';

      if (this.viewModel.generationSiteId === undefined) {
        return '排出場を選択してください';
      }

      if (this.isBatchRegister) {
        return '複数受注の一括登録の場合は標準作業設定はできません';
      }

      return '';
    },
    isBatchRegister(): boolean {
      return (
        this.viewModel !== undefined &&
        this.viewModel.dateCollectablePeriodType === DateCollectablePeriodTypes.BatchRegister
      );
    },
    isRoutingGroupDisabled(): boolean {
      if (this.viewModel === undefined) return true;
      if (this.viewModel.generationSiteId === undefined) return true;
      if (this.viewModel.orderGroupId === undefined) return true;
      if (this.viewModel.dateCollectablePeriodType >= 2) return true;
      if (this.hasMultipleCarNum) return true;

      return false;
    },
    routingGroupDisabledReason(): string {
      if (!this.viewModel) return '';

      if (this.viewModel.generationSiteId === undefined) {
        return '排出場を選択してください';
      }
      if (this.viewModel.orderGroupId === undefined) {
        return '担当グループを選択してください';
      }
      if (this.viewModel.dateCollectablePeriodType >= 2) {
        return '受注を指定したい場合は到着日時の詳細設定をリセットする必要があります';
      }
      if (this.hasMultipleCarNum) {
        return '車台数が複数の場合は、ルート回収の指定はできません';
      }

      return '';
    },
    isIsFixedArrivalTimeReportNeededDisabled(): boolean {
      return this.viewModel === undefined || this.viewModel.generationSiteId === undefined || this.hasMultipleCarNum;
    },
    isFixedArrivalTimeReportNeededDisabledReason(): string {
      if (!this.viewModel) return '';

      if (this.viewModel.generationSiteId === undefined) {
        return '排出場を選択してください';
      }
      if (this.hasMultipleCarNum) {
        return '車台数が複数の場合は、到着予定時刻の報告の指定はできません';
      }

      return '';
    },
    /**
     * 【稼ぎ頭用】
     * 稼ぎ頭の受注の区分の入力が必要かどうか。
     * 作業に作業種別が「交換」・「引上」・「積込」の項目が含まれている場合に必要となる。
     */
    isErpOrderCategoryRequired(): boolean {
      if (!this.masters?.officeSetting.canUseErp || this.viewModel === undefined) return false;

      return this.viewModel.generationSiteTaskItems.some((task) => {
        if (!task.taskType) return false;

        return ['ReplaceAtGenerationSite', 'FetchAtGenerationSite', 'LoadAtGenerationSite'].includes(
          task.taskType.baseTaskType.name
        );
      });
    },
    /**
     * 【稼ぎ頭用】
     * 現場作業に予定数量の入力が必要かどうか。
     * 予定数量は、稼ぎ頭で処分の品目登録するために使われる。
     */
    isApparentQuantityRequired(): boolean {
      return !!this.masters?.officeSetting.canUseErp && !!this.viewModel?.erpOrderForm?.withDisposalItemFromScheduling;
    },
  },
  watch: {
    /**
     * メッセージが表示されてから5秒後に閉じる
     */
    isLessThanDurationOfTasksMessageActive(value) {
      if (value === true) {
        setTimeout(() => {
          this.isLessThanDurationOfTasksMessageActive = false;
        }, 5000);
      }
    },
    'viewModel.inputAttachments'(input: File[]) {
      if (this.viewModel === undefined || input.length === 0) return;
      const addedFiles = input.filter((attachment) => {
        return !this.viewModel!.attachmentsToAdd.map((a) => {
          return a.name;
        }).includes(attachment.name);
      });
      if (addedFiles.length !== 0) {
        this.viewModel.attachmentsToAdd = [...this.viewModel.attachmentsToAdd, ...addedFiles];
      }
      this.viewModel.inputAttachments = [];
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.orderFormPanel.openFormEvent.on(this.onOpenForm);
    this.$context.panels.orderFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    formatDateForField,
    dateToMd,
    dateToYyMdDaysOfWeek,
    dateToYymmdd,
    getRoutableOrderDisplayName,
    sanitizeHours,
    sanitizeMinutes,
    /**
     * public ではあるが基本外からは呼ばず、PanelManager を通して操作される
     * @public
     */
    async open(order: Maybe<IOrderEntity> = undefined, option?: IOrderFormPanelOption): Promise<void> {
      if (this.isActive) return;
      const initialLoadMasterValues =
        option?.initialFormValues !== undefined
          ? this.getPreloadableFormObjectFromFormValues(option.initialFormValues)
          : order;
      if (this.masters === undefined || option?.useCache !== true) {
        this.masters = await this.loadAsyncData(this.$nuxt.context, initialLoadMasterValues);
      }
      const orderValidation = new OrderValidation();
      const clientDefaultCondition = {
        keywords: undefined,
        since: undefined,
        orderBy: ClientsByKeywordsOrder.CreatedAtDesc,
      };
      const clientLoader = new Client(this.$context, clientDefaultCondition);

      const generationSiteDefaultCondition: GenerationSitesByKeywordsCondition = {
        clientId: undefined,
        keywords: undefined,
        since: undefined,
        orderBy: GenerationSitesByKeywordsOrder.CreatedAtDesc,
      };
      const generationSiteLoader = new GenerationSite(this.$context, generationSiteDefaultCondition);

      const canUseErp = this.masters.officeSetting.canUseErp;
      const transportationClients = canUseErp
        ? await this.erpOrderApplicationService.getAllTransportationClients()
        : undefined;
      const erpOrder =
        order && canUseErp ? await this.erpOrderApplicationService.getErpOrderByOrderId(order.id) : undefined;

      this.viewModel = new OrderForm(
        clientDefaultCondition,
        clientLoader,
        generationSiteDefaultCondition,
        generationSiteLoader,
        this.masters.client,
        this.masters.officeSetting,
        this.masters.generationSite,
        this.masters.orderGroups,
        this.masters.disposalSites,
        this.masters.wasteTypes,
        this.masters.containerTypes,
        this.masters.containerTypeTaskTypes,
        this.masters.packingStyles,
        this.masters.collectablePeriodTemplates,
        this.masters.drivers,
        this.masters.carTypes,
        this.masters.cars,
        this.masters.assignableDriverAttendances,
        order,
        orderValidation,
        option?.initialFormValues,
        option?.formTitle,
        this.masters.taskTypes,
        canUseErp,
        transportationClients,
        erpOrder
      );

      this.businessDaysService = new BusinessDaysService(this.masters.holidayRules);

      // 機能を開放していいかどうかチェック
      const [isOrderAcceptanceCheckAvailable] = await this.loadFeatureAvailabilities();
      this.isOrderAcceptanceCheckAvailable = isOrderAcceptanceCheckAvailable;

      // @ts-ignore
      await (this.$refs.RSideform as RSideformInstance).open();

      // 複製時に宵積み日付が過去の日付になっている場合宵積み日付を更新する
      // refが存在しないパターンがある
      if (this.$refs.dateCollectablePeriodInput) {
        (this.$refs.dateCollectablePeriodInput as any).checkDate();
      }

      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);

      // 会社や排出場が削除されていた場合、viewModel の中で勝手に会社と排出場が undefined に設定される事がある。
      // この状態でそのまま validateOrderForm を走らすと本来 null になるべきではないフィールドが null になって
      // しまいエラーになる。そこで、isFormValid な時だけ validateOrderForm を走らせる。会社や排出場が
      // undefined になっている場合はそもそも isFormValid が true にならないため、これで通らなくなる。
      if (order && this.viewModel.isFormValid) {
        if (await this.validateOrderForm()) this.onValidationError();
      }

      // 呼び出し元からどこまでスクロールするかのターゲットをもらった場合、フォーム内のその位置までスクロールする
      if (option?.scrollTarget) {
        let targetClass: Maybe<string>;
        switch (option?.scrollTarget) {
          case OrderScrollTarget.PreloadStatus:
            targetClass = 'r-order-form__preload';
            break;
          default:
            break;
        }
        if (targetClass) {
          const scrollTargetElements = document.getElementsByClassName(targetClass);
          if (scrollTargetElements.length > 0 && scrollTargetElements[0]) {
            scrollTargetElements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }

      if (this.viewModel.order !== undefined && this.viewModel.carNum !== this.viewModel.order.carNum) {
        this.$context.snackbar.info('稼ぎ頭に連携される受注は、車台数1台で設定されます');
      }
    },
    /**
     * public ではあるが基本外からは呼ばず、PanelManager を通して操作される
     * @public
     */
    async close(): Promise<void> {
      if (this.isActive === false) return;
      // @ts-ignore
      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeFormArgs: ICloseEntityFormArgs<IOrderEntity> = {
        entity: this.viewModel!.order,
        createdEntity: this.viewModel!.createdOrder,
        updatedEntity: this.viewModel!.updatedOrder,
        removedEntity: undefined,
      };
      this.$context.panels.orderFormPanel.closeFormEvent.emit(closeFormArgs);
    },
    /**
     * https://github.com/FanfareInc/rin/pull/1396#discussion_r798198747
     * 「受注パネル」は会社や排出場など数が大量（数万オーダー）になる様な選択肢を表示させているが、
     * この様な選択肢はデータ量の問題で一度に読み込むことが出来ない。
     * そのため遅延処理を行い、パネルが表示された後に少しずつサーバーからデータを取得している。
     *
     * 一方、受注の新規登録や、（既存の受注の）登録内容の編集（や排出場の編集へ移動して戻ってきた場合）を行う時に、
     * 既に指定されている会社や排出場を選択肢として表示しないと、会社や排出場に関するデータが空の状態で受注を登録することになり、
     * 矛盾した（会社や排出場に関するデータは存在しないが、時間や作業内容などが登録された）受注が登録されることになる。
     * その矛盾を回避するため、会社や排出場のデータは受注を登録する前に先に読み込んでおく必要がある。
     * これが、this.masters.client(会社) と this.masters.generationSite(排出場)の役目である。
     *
     * 「続けて登録」の場合は、useCache をオンにして、既にサーバーから取得したデータを再利用し、
     * サーバーから新規に（もしくは再度）取得するデータの量を最低限に抑えている。
     * それにより、this.mastersの内容が次に開かれたパネルでも再利用される。
     *
     * しかし、this.masters.clientやthis.masters.generationSiteが次に開いたパネルにも反映される場合、
     * 次のパネルから登録する受注の内容に前回開いたパネルの受注の内容を使用するものと判断される。
     * そのため、前回の受注登録固有のデータ（「登録済みの備考欄」の内容）が表示されてしまう問題があった。
     *
     * その問題を解決するため、続けて登録を行う事は新たにフォームの内容を設定する事と等価であるという思想の元、
     * this.masters.clientとthis.masters.generationSiteをリセットしている。
     * （this.masters.clientなどはデータ量がパネルの挙動に大きく影響する程大きなデータ量ではないため、
     * キャッシュされるべきマスターの内容ではなく、各受注登録固有の内容（フォームの内容）と判断した）
     */
    initValueOfMasters(masters: Maybe<Masters>): Maybe<Masters> {
      const initialMaster = this.getInitialMaster();
      return _.assign(masters, initialMaster);
    },
    getInitialMaster(): InitialMaster {
      return {
        client: undefined,
        generationSite: undefined,
      };
    },
    async onOpenForm(args: IOpenEntityFormArgs<IOrderEntity, IOrderFormPanelOption>): Promise<void> {
      await this.open(args.entity, args.option);
    },
    async onCloseForm(forceClose: boolean = false): Promise<boolean> {
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (forceClose === false && this.viewModel !== undefined && this.viewModel.isDirty) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isCloseConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isCloseConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    /**
     * 宵積みを設定
     *
     * @param {PreloadStatus} preloadStatus - The preload status to be set.
     * NOTE: 「宵積み不可」・「宵積み許可」・「宵積みキャンセル」から「宵積み指定」に変更された時、かつ、
     * 翌営業日が選択可能な時に限り通知して該当のエレメントに移動させる。
     */
    async onSelectPreload(preloadStatus: PreloadStatus): Promise<void> {
      if (!this.viewModel) throw new Error('Impossible!');
      if (preloadStatus === PreloadStatus.NotAllowed || preloadStatus === PreloadStatus.Modifiable) {
        this.viewModel.setPreload(preloadStatus);
        for (let i = 0; i < this.viewModel.dateCollectablePeriodItems.length; i++) {
          this.viewModel.dateCollectablePeriodItems[i].unloadDate = undefined;
        }
        // パネルで宵積みなしになった場合には複数候補日の宵積み日をクリアする
        for (let i = 0; i < this.viewModel.orderPlanCollectablePeriodItems.length; i++) {
          this.viewModel.orderPlanCollectablePeriodItems[i].unloadDate = undefined;
        }
      } else if (preloadStatus === PreloadStatus.Forced) {
        if (this.viewModel.recurringOrderSettings) {
          const confirmDialogPromise = new Promise<boolean>((resolve) => {
            this.isPreloadConfirmDialogActive = true;
            this.preloadConfirmDialogResolver = resolve;
          });

          const forcePreload = await confirmDialogPromise;

          if (!forcePreload) {
            return await this.onSelectPreload(PreloadStatus.NotAllowed);
          }
        }

        // NOTE: 「宵積み不可」・「宵積み許可」・「宵積みキャンセル」から「宵積み指定」に変更された時、かつ、
        // 翌営業日が選択可能な時に限り通知して該当のエレメントに移動させる。
        if (this.viewModel.preloadStatus !== PreloadStatus.Forced && !this.viewModel.isUnloadDateDisabled) {
          this.$context.snackbar.primary('宵積みが指定されたため、到着日時で荷下ろし日が選択できるようになりました。');

          const scrollToEl = document.getElementsByClassName('r-order-form__date-and-time')[0];
          scrollToEl.scrollIntoView({ behavior: 'smooth' });
        }

        if (!this.businessDaysService) throw new Error('Business day service not initialized');

        this.viewModel.setPreload(preloadStatus);
        for (let i = 0; i < this.viewModel.dateCollectablePeriodItems.length; i++) {
          this.viewModel.dateCollectablePeriodItems[i].unloadDate = this.businessDaysService.getNextBusinessDate(
            this.viewModel.dateCollectablePeriodItems[i].getDate()
          );
        }
        // パネルで宵積みありになった場合には複数候補日の宵積み日を翌営業日で入れる
        for (let i = 0; i < this.viewModel.orderPlanCollectablePeriodItems.length; i++) {
          this.viewModel.orderPlanCollectablePeriodItems[i].unloadDate = this.businessDaysService.getNextBusinessDate(
            this.viewModel.orderPlanCollectablePeriodItems[i].getDate()
          );
        }

        if (this.viewModel.dateCollectablePeriodType === DateCollectablePeriodTypes.Recurring) {
          this.viewModel.dateCollectablePeriodType = DateCollectablePeriodTypes.Default;
        }
      }
    },
    onConfirmPreloadDialog(value: boolean) {
      this.isPreloadConfirmDialogActive = false;

      if (this.preloadConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');

      this.preloadConfirmDialogResolver(value);
    },
    onConfirmDateCollectablePeriodTypeChangeDialog(value: boolean) {
      this.isDateCollectablePeriodTypeChangeConfirmDialogActive = false;

      if (this.dateCollectablePeriodTypeChangeConfirmDialogResolver === undefined)
        throw new Error('Resolver has not been set!');

      this.dateCollectablePeriodTypeChangeConfirmDialogResolver(value);
    },

    onConfirmRecurringOrderDialog(value: boolean) {
      this.isRecurringOrderConfirmDialogActive = false;

      if (this.recurringOrderConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');

      this.recurringOrderConfirmDialogResolver(value);
    },
    onConfirmsFixedArrivalTimeResetChangeDialog(value: boolean) {
      this.isFixedArrivalTimeResetConfirmDialogActive = false;

      if (this.fixedArrivalTimeResetConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');

      this.fixedArrivalTimeResetConfirmDialogResolver(value);
    },
    // 複数候補日予約ダイアログの完了
    onConfirmMultipleCandidateDatesDialog() {
      if (this.viewModel === undefined) return;
      // 編集確定したら編集用情報をパネル側情報にセットする
      this.viewModel.setOrderPlanCollectablePeriodItems(this.viewModel.editOrderPlanCollectablePeriodItems);
      // 単数の場合はDefault、複数の場合はScheduleに変更する
      const hasCandidateDates = this.viewModel.orderPlanCollectablePeriodItems.length > 1;
      this.viewModel.dateCollectablePeriodType = hasCandidateDates
        ? DateCollectablePeriodTypes.Plan
        : DateCollectablePeriodTypes.Default;

      // 複数候補日を持っていた場合には繰り返し受注を解除する
      if (hasCandidateDates) {
        this.viewModel.recurringOrderSettings = undefined;
      }
      // 複数候補日入力完了後にcollectablePeriodのエラーをクリアする
      this.viewModel.orderValidation.resetErrorByField('collectablePeriod');
    },

    getPreloadableFormObjectFromFormValues(formValues: IFormValues): IPreloadableFormObject {
      // loadAsyncDataに引数で渡されて、driverAttendancesByDateRangeでdateが使われる。
      // 複数日の一括処理の場合、dateCollectablePeriodItemsを複数持っている可能性があるが、
      // 最初の1つだけのチェックで問題なし
      return {
        assignableDrivers: formValues.assignableDrivers,
        date: formValues.dateCollectablePeriodItems[0].getDate(),
        clientId: formValues.clientId,
        generationSiteId: formValues.generationSiteId,
      };
    },

    async loadAsyncData(_context: Context, initialLoadMasterValues: Maybe<IPreloadableFormObject>): Promise<Masters> {
      // 乗務員のリストは all で取りたいものの過去に作成されたオーダーの乗務員は論理削除されている可能性があり、
      // この様な乗務員を取得するために別途 ID を指定して取得しており、そのための小細工
      const driverIdSet: Set<PersistentId> = new Set<PersistentId>();
      if (initialLoadMasterValues?.assignableDrivers !== undefined)
        initialLoadMasterValues.assignableDrivers.forEach((assignableDriver) => {
          driverIdSet.add(assignableDriver.driverId);
        });
      const formData = await getOrderFormData(
        this.$context,
        initialLoadMasterValues?.date,
        initialLoadMasterValues?.clientId,
        initialLoadMasterValues?.generationSiteId,
        driverIdSet.toArray()
      );

      const transportationClients = formData.officeSetting.canUseErp
        ? await this.erpOrderApplicationService.getAllTransportationClients()
        : undefined;

      return {
        officeSetting: formData.officeSetting,
        client: formData.client,
        generationSite: formData.generationSite,
        orderGroups: formData.orderGroups,
        disposalSites: formData.disposalSites,
        wasteTypes: formData.wasteTypes,
        containerTypes: formData.containerTypes,
        containerTypeTaskTypes: formData.containerTypeTaskTypes,
        collectablePeriodTemplates: formData.collectablePeriodTemplates,
        drivers: formData.drivers,
        carTypes: formData.carTypes,
        cars: formData.cars,
        assignableDriverAttendances: formData.assignableDriverAttendances,
        taskTypes: formData.taskTypes,
        holidayRules: formData.holidayRule,
        packingStyles: formData.packingStyles,
        transportationClients,
      };
    },
    async loadFeatureAvailabilities(): Promise<boolean[]> {
      return await this.$context.features.getFeatureAvailabilities([Features.OrderAcceptanceCheck]);
    },
    async validateOrderForm(): Promise<boolean> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const viewModel = this.viewModel;

      const validateOrder = await this.orderApplicationService.validateOrder(viewModel.getValidateOrderData());
      viewModel.orderValidation.setErrorsByInfeasibilities(
        viewModel.assignableDrivers.map((assignableDriver) => assignableDriver.driverId),
        viewModel.assignedCarId,
        validateOrder.infeasibilities
      );
      return viewModel.orderValidation.hasErrors;
    },
    checkClientAndGenerationSiteConsistency(): boolean {
      const viewModel = this.viewModel;
      if (viewModel === undefined) throw new Error(`Impossible!`);

      // NOTE 受注登録後に排出場の会社を変更すると会社と排出場の組が不正になってしまう事があり、
      //  そのような状態になってしまっている場合には登録ができないので弾いておく。本来はこのような状態で
      //  登録ボタンを押せる様になってはいけない。
      if (viewModel.client && viewModel.clientId && viewModel.generationSite && viewModel.generationSiteId) {
        const clientIsBroken = viewModel.client.persistentId !== viewModel.clientId;
        const generationSiteIsBroken = viewModel.generationSite.persistentId !== viewModel.generationSiteId;
        const clientIdDoesntMatch = viewModel.clientId !== viewModel.generationSite.clientId;
        if (clientIsBroken || generationSiteIsBroken || clientIdDoesntMatch) {
          viewModel.generationSiteId = undefined;
          viewModel.generationSite = undefined;

          this.$context.snackbar.warning('会社と排出場の組が不正です。お手数ですがもう一度排出場を選択して下さい。', {
            described: true,
          });
          return false;
        }
      }
      return true;
    },
    async onClickRegisterButton(continueRegistration: boolean): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const viewModel = this.viewModel;
      viewModel.isRegistering = true;

      // まず登録しても大丈夫かどうかを確認する
      // エラーがあれば登録はできない
      if (await this.validateOrderForm()) {
        this.onValidationError();
        viewModel.isRegistering = false;
        return;
      }

      if (this.checkClientAndGenerationSiteConsistency() === false) {
        viewModel.isRegistering = false;
        return;
      }

      const durationAtGenerationSite = viewModel.getDurationAtGenerationSite();

      if (viewModel.formMode === FormMode.Register) {
        const generationSiteTasks = viewModel.getCreateGenerationSiteTaskInput();
        const irregularTasks = viewModel.getCreateIrregularTaskInput();
        // plan 関連以外は共通
        const baseInput = {
          orderGroupId: viewModel.orderGroupId!,
          generationSiteId: viewModel.generationSiteId!,
          durationAtGenerationSite: durationAtGenerationSite!,
          routeCollectionAllowed: viewModel.routeCollectionAllowed,
          preloadStatus: viewModel.preloadStatus,
          // TODO: 処分場の入退場時間の後続リリースで削除
          assignedDisposalSiteIds: viewModel.disposalSiteIds!,
          // TODO: 処分場の入退場時間の後続リリースで削除
          disposalSiteAssignmentType: viewModel.disposalSiteAssignmentType!,
          assignedDisposalSitesAndType: {
            orderDisposalSites: viewModel.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
              return {
                disposalSiteId: orderAssignedDisposalSite.disposalSiteId,
                durationAtEntrance: orderAssignedDisposalSite.durationAtEntrance,
                priority: orderAssignedDisposalSite.priority,
              };
            }),
            disposalSiteAssignmentType: viewModel.disposalSiteAssignmentType,
          },
          assignableDriversAndNum: {
            minAssignedDriverNum: viewModel.driverNum,
            maxAssignedDriverNum: viewModel.driverNum,
            driverAssignmentType: viewModel.driverAssignmentType,
            assignableDrivers: viewModel.assignableDrivers,
          },
          assignedCarId: viewModel.assignedCarId,
          assignableCarTypeIds: viewModel.assignableCarTypeIds,
          assignedBaseSiteId: undefined, // FIXME
          minAssignedCarNum: viewModel.carNum,
          maxAssignedCarNum: viewModel.carNum,
          carNum: viewModel.carNum,
          note: viewModel.note,
          noteForAssignedDriver: '',
          attachmentsToAdd: viewModel.attachmentsToAdd,
          avoidHighways: viewModel.avoidHighways,
          fixedArrivalTime: viewModel.fixedArrivalTime,
          isFixedArrivalTimeReportNeeded: viewModel.isFixedArrivalTimeReportNeeded,
          marginTypeOfFixedArrivalTime: viewModel.marginTypeOfFixedArrivalTime,
          marginOfFixedArrivalTime: viewModel.marginOfFixedArrivalTime,
          routingGroup: viewModel.routingGroup
            ? { orderIds: viewModel.routingGroup.orderIds.filter((id) => typeof id === 'string') as string[] }
            : undefined,
          fixedDisplayOnReservation: viewModel.fixedDisplayOnReservation,
          fixedDisplayOnReservationName: viewModel.fixedDisplayOnReservationName,
          schedulingPriority: viewModel.schedulingPriority,
          recurringSettings: viewModel.getCreateOrderRecurringSettingsInput(),
          status: OrderStatus.Active,
          generationSiteTasks,
          irregularTasks,
        };

        const createOrderInput: ICreateData[] = [];
        if (viewModel.dateCollectablePeriodType === DateCollectablePeriodTypes.BatchRegister) {
          // 一括登録の場合は、RepresentativeOrderPlanInput が使えないので、個別に生成する
          for (const item of viewModel.dateCollectablePeriodItems) {
            const date = item.getDate();
            const collectablePeriodTemplateName = item.collectablePeriodTemplateName;
            const collectablePeriodStart = item.getCollectablePeriodStartOrDistinctTime();
            const collectablePeriodEnd = item.getCollectablePeriodEndOrDistinctTime();
            const unloadDate = item.unloadDate;
            const carNum = item.carNum!;
            const input: ICreateData = {
              ...baseInput,
              carNum,
              plan: {
                fixed: {
                  date,
                  collectablePeriodTemplateName,
                  collectablePeriodStart,
                  collectablePeriodEnd,
                  unloadDate,
                },
              },
              date,
              collectablePeriodTemplateName,
              collectablePeriodStart,
              collectablePeriodEnd,
              unloadDate,
            };
            createOrderInput.push(input);
          }
        } else {
          const plan = viewModel.getRepresentativeOrderPlanInput();
          const item = viewModel.defaultDateCollectablePeriodItem;
          createOrderInput.push({
            ...baseInput,
            plan,
            // date 関連は、複数候補日は plan から取得されるので、単数日相当の値でよい
            date: item.getDate(),
            collectablePeriodTemplateName: item.collectablePeriodTemplateName,
            collectablePeriodStart: item.getCollectablePeriodStartOrDistinctTime(),
            collectablePeriodEnd: item.getCollectablePeriodEndOrDistinctTime(),
            unloadDate: item.unloadDate,
          });
        }

        const [entity] = await this.orderApplicationService.create(createOrderInput);

        viewModel.createdOrder = entity;

        const date = viewModel.dateCollectablePeriodItems[0].date;

        // NOTE: 到着日時が基本設定の場合は snackbar に日付も表示する
        if (viewModel.dateCollectablePeriodType === DateCollectablePeriodTypes.Default && date !== undefined) {
          this.$context.snackbar.success(`${format(date, 'yyyy年MM月dd日')} 受注情報の登録完了`);
        } else {
          // NOTE: 複数候補日、繰り返し受注、一括登録の場合は日付を表示しない
          this.$context.snackbar.success('受注情報の登録完了');
        }

        if (this.viewModel?.canUseErp) await this.createErpOrder(entity, !!createOrderInput[0].recurringSettings);
      } else if (viewModel.formMode === FormMode.Edit) {
        const order = viewModel.order!;

        let fixedArrivalTime = viewModel.fixedArrivalTime;
        if (fixedArrivalTime !== undefined && this.viewModel.isFixedArrivalTimeConfirmNeeded) {
          const isFixedArrivalTimeResetConfirmPromise = new Promise<boolean>((resolve) => {
            this.isFixedArrivalTimeResetConfirmDialogActive = true;
            this.fixedArrivalTimeResetConfirmDialogResolver = resolve;
          });

          if (await isFixedArrivalTimeResetConfirmPromise) {
            fixedArrivalTime = undefined;
          } else {
            viewModel.isRegistering = false;
            return;
          }
        }

        const dateCollectablePeriodItem = this.viewModel.defaultDateCollectablePeriodItem;
        let includeFollowingRecurringOrders: Maybe<boolean>;

        if (viewModel.order?.recurringSettings) {
          // 日が変更されていた場合、「これ以降すべて」は意味が分からないので選択できない様にする
          // 繰り返し受注のdateはorder.dateが起点になっている、入力されてない場合はfixed、それもない場合はcandidateDatesの先頭を入れる
          const date =
            viewModel.order.date ?? viewModel.order.plan?.fixed?.date ?? viewModel.order.plan?.candidateDates![0].date;
          // order.dateとorder.planがどちらもない場合はエラー
          if (date === undefined) throw new Error(`Impossible!`);
          // 宵積みになっている場合は繰り返し受注としての編集はせず個別の編集として扱う
          // 宵積みの場合includeFollowingRecurringOrdersはfalseにして(ダイアログ先のこの予定を変更する、と同値)ダイアログを出さない
          // 異なる日付に設定した場合もダイアログを出さない
          if (
            viewModel.preloadStatus !== PreloadStatus.Forced &&
            isSameDay(date, dateCollectablePeriodItem.getDate())
          ) {
            includeFollowingRecurringOrders = await (this.$refs.recurringOrderRegisterDialog as any).open();
            if (includeFollowingRecurringOrders === undefined) {
              viewModel.isRegistering = false;
              return;
            }
          } else {
            includeFollowingRecurringOrders = false;
          }
        }
        const collectablePeriodStart = dateCollectablePeriodItem.getCollectablePeriodStartOrDistinctTime();
        const collectablePeriodEnd = dateCollectablePeriodItem.getCollectablePeriodEndOrDistinctTime();
        const collectablePeriodTemplateName = viewModel.getCollectablePeriodTemplateNameFromId(
          dateCollectablePeriodItem.collectablePeriodTemplateId
        );
        const generationSiteTasks = viewModel.getCreateGenerationSiteTaskInput();
        const irregularTasks = viewModel.getCreateIrregularTaskInput();

        const entity = await this.orderApplicationService.update({
          id: order.persistentId,
          date: dateCollectablePeriodItem.getDate(),
          // update では BatchRegister がないので、representative をそのまま使ってよい
          plan: viewModel.getRepresentativeOrderPlanInput(),
          orderGroupId: viewModel.orderGroupId!,
          generationSiteId: viewModel.generationSiteId!,
          collectablePeriodTemplateName,
          collectablePeriodStart,
          collectablePeriodEnd,
          durationAtGenerationSite: durationAtGenerationSite!,
          routeCollectionAllowed: viewModel.routeCollectionAllowed,
          preloadStatus: viewModel.preloadStatus,
          unloadDate: dateCollectablePeriodItem.unloadDate,
          // TODO: 処分場の入退場時間の後続リリースで削除
          assignedDisposalSiteIds: viewModel.disposalSiteIds!,
          // TODO: 処分場の入退場時間の後続リリースで削除
          disposalSiteAssignmentType: viewModel.disposalSiteAssignmentType!,
          assignedDisposalSitesAndType: {
            orderDisposalSites: viewModel.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
              return {
                disposalSiteId: orderAssignedDisposalSite.disposalSiteId,
                durationAtEntrance: orderAssignedDisposalSite.durationAtEntrance,
                priority: orderAssignedDisposalSite.priority,
              };
            }),
            disposalSiteAssignmentType: viewModel.disposalSiteAssignmentType,
          },
          assignableDriversAndNum: {
            minAssignedDriverNum: viewModel.driverNum,
            maxAssignedDriverNum: viewModel.driverNum,
            driverAssignmentType: viewModel.driverAssignmentType,
            assignableDrivers: viewModel.assignableDrivers,
          },
          assignedCarId: viewModel.assignedCarId,
          assignableCarTypeIds: viewModel.assignableCarTypeIds,
          assignedBaseSiteId: undefined, // TODO
          minAssignedCarNum: viewModel.carNum,
          maxAssignedCarNum: viewModel.carNum,
          carNum: viewModel.carNum,
          note: viewModel.note,
          noteForAssignedDriver: order.noteForAssignedDriver,
          attachmentsToAdd: viewModel.attachmentsToAdd,
          attachmentsToRemove: viewModel.attachmentsToRemove,
          avoidHighways: viewModel.avoidHighways,
          fixedArrivalTime,
          isFixedArrivalTimeReportNeeded: viewModel.isFixedArrivalTimeReportNeeded,
          marginTypeOfFixedArrivalTime: viewModel.marginTypeOfFixedArrivalTime,
          marginOfFixedArrivalTime: viewModel.marginOfFixedArrivalTime,
          routingGroup:
            viewModel.routingGroup !== undefined
              ? { orderIds: viewModel.routingGroup.orderIds.filter((id) => typeof id === 'string') as string[] }
              : undefined,
          fixedDisplayOnReservation: viewModel.fixedDisplayOnReservation,
          fixedDisplayOnReservationName: viewModel.fixedDisplayOnReservationName,
          schedulingPriority: viewModel.schedulingPriority,
          status: order.status,
          includeFollowingRecurringOrders,
          generationSiteTasks,
          irregularTasks,
        });

        viewModel.updatedOrder = entity;

        const date = viewModel.dateCollectablePeriodItems[0].date;

        // NOTE: 到着日時が基本設定の場合は snackbar に日付も表示する
        if (viewModel.dateCollectablePeriodType === DateCollectablePeriodTypes.Default && date !== undefined) {
          this.$context.snackbar.success(`${format(date, 'yyyy年MM月dd日')} 受注情報の更新完了`);
        } else {
          // NOTE: 複数候補日、繰り返し受注、一括登録の場合は日付を表示しない
          this.$context.snackbar.success('受注情報の更新完了');
        }

        if (this.viewModel?.canUseErp) await this.updateErpOrder(entity, includeFollowingRecurringOrders);

        // 本当は、作成完了に割り振られているかを確認するべきだが、orderからたどるのが難しいので、
        // 今表示している画面が作成完了画面で、受注パネルを保存したとき再作成を促す
        try {
          // 調べた限り、 UserSetting:1 の 1 は 1にしかなり得なさそう
          const settings = JSON.parse(window.localStorage.getItem('UserSetting:1') || '{}');
          if (
            this.$route.name === 'schedule' &&
            (this.$route.hash === '#schedule' || (settings?.scheduleTab === 0 && this.$route.hash === ''))
          ) {
            this.$context.snackbar.info('受注情報の編集後は配車表の再作成が必要です。');
          }
          //   JSON.parseで死ぬ可能性があるのでケアしておくがあまりこだわらない
        } catch (Err) {
          // eslint-disable-next-line no-console
          console.warn(
            `受注登録ボタンをクリックしたが window.localStorage.getItem('UserSetting:1') の JSON can't parsed`
          );
        }
      }
      viewModel.isRegistering = false;

      this.$rinGtm.push(continueRegistration ? RinEventNames.COMPLETE_INPUT_CONTINUE : RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.ORDER,
        [AdditionalInfoKeys.MODE]:
          viewModel.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });

      await this.close();
      if (continueRegistration) {
        // 続けて登録する時に一部のキーを初期化する
        this.masters = this.initValueOfMasters(this.masters);
        await this.$context.panels.orderFormPanel.open(undefined, { useCache: true });
      }
    },

    generateErpOrderItemForm(order: IOrderEntity): ErpOrderItemForm[] {
      ensure(this.viewModel);

      /**
       * Get input form from created generation site task
       * @param createdTask
       */
      const getGenerationSiteTaskItemForm = (
        createdTask: AggregatedGenerationSiteTaskEntity
      ): IGenerationSiteTaskItem => {
        ensure(this.viewModel);
        const generationSiteTasksItemsForm = this.viewModel.generationSiteTaskItems;
        // NOTE: 作業毎に `getGenerationSiteTaskKey` がユニークなので find でよい。
        const item = generationSiteTasksItemsForm.find((task) => {
          return (
            this.orderApplicationService.getGenerationSiteTaskKey(createdTask) ===
            this.orderApplicationService.getGenerationSiteTaskKey(task)
          );
        });

        if (item === undefined)
          throw new Error('Could not find generation site task form from created generation site.');

        return item;
      };

      // erpOrderItems を、作られた generationSiteTasks/irregularTasks から構成する
      const generationSiteTasks = order.generationSiteTasks;
      const irregularTaskIds = order.irregularTaskIds;

      const erpOrderItems: ErpOrderItemForm[] = [
        ...generationSiteTasks.map((item) => {
          const generationSiteTaskItemForm = getGenerationSiteTaskItemForm(item);
          return {
            generationSiteTaskId: item.id,
            irregularTaskId: undefined,
            apparentQuantity: generationSiteTaskItemForm.apparentQuantity,
            apparentQuantityUnit: generationSiteTaskItemForm.apparentQuantityUnit,
          };
        }),
        ...irregularTaskIds.map((id) => {
          return {
            generationSiteTaskId: undefined,
            irregularTaskId: id,
            apparentQuantity: undefined,
            apparentQuantityUnit: undefined,
          };
        }),
      ];

      return erpOrderItems;
    },

    async createErpOrder(order: IOrderEntity, isRecurring: boolean): Promise<void> {
      ensure(this.viewModel);
      if (this.viewModel.canUseErp === false) return;

      const erpOrderForm = this.viewModel.erpOrderForm;
      ensure(erpOrderForm);
      const withDisposalItemFromScheduling = this.isErpOrderCategoryRequired
        ? erpOrderForm.withDisposalItemFromScheduling
        : false;
      const erpOrderItems = this.generateErpOrderItemForm(order);

      try {
        await this.erpOrderApplicationService.createErpOrder({
          orderId: order.id,
          transportationClientId: erpOrderForm.transportationClient.id,
          withDisposalItemFromScheduling,
          note: erpOrderForm.note,
          erpOrderItems,
          isRecurring,
        });
      } catch (error) {
        this.$context.snackbar.error('稼ぎ頭の受注登録に失敗しました。');
      }
    },

    async updateErpOrder(order: IOrderEntity, includeFollowingRecurringOrders: Maybe<boolean>): Promise<void> {
      ensure(this.viewModel);
      if (this.viewModel.canUseErp === false) return;

      const erpOrderForm = this.viewModel.erpOrderForm;

      if (erpOrderForm === undefined) return; // NOTE: 稼ぎ頭に連携されていない受注の場合

      ensure(erpOrderForm.id);
      const withDisposalItemFromScheduling = this.isErpOrderCategoryRequired
        ? erpOrderForm.withDisposalItemFromScheduling
        : false;
      const erpOrderItems = this.generateErpOrderItemForm(order);

      try {
        await this.erpOrderApplicationService.updateErpOrder({
          id: erpOrderForm.id,
          orderId: order.id,
          transportationClientId: erpOrderForm.transportationClient.id,
          withDisposalItemFromScheduling,
          note: erpOrderForm.note,
          erpOrderItems,
          includeFollowingRecurringOrders,
        });
      } catch (error) {
        this.$context.snackbar.error('稼ぎ頭の受注更新に失敗しました。');
      }
    },
    // NOTE: 瞬間チェックダイアログから候補日を受注登録する
    registerFromDialog(orderAcceptanceCheckData: IOrderAcceptanceCheckData): void {
      if (this.viewModel === undefined) return;
      this.orderAcceptanceCheckDialogValue = false;
      this.viewModel.defaultDateCollectablePeriodItem.date = orderAcceptanceCheckData.date;
      this.viewModel.defaultDateCollectablePeriodItem.unloadDate = orderAcceptanceCheckData.unloadDate;
      this.onClickRegisterButton(false);
    },
    async onHoursAtGenerationSiteTaskChange(value: number): Promise<void> {
      if (this.viewModel === undefined) return;

      const hours: number = sanitizeNaturalNumber(value);
      await Vue.nextTick();
      this.isLessThanDurationOfTasksMessageActive =
        this.viewModel.generationSiteTaskDuration.setHoursAtGenerationSite(hours);
    },
    async onMinutesAtGenerationSiteTaskChange(value: number): Promise<void> {
      if (this.viewModel === undefined) return;

      const minutes: number = sanitizeNaturalNumber(value);
      await Vue.nextTick();
      this.isLessThanDurationOfTasksMessageActive =
        this.viewModel.generationSiteTaskDuration.setMinutesAtGenerationSite(minutes);
    },
    onChangeOrderGroup(_value: Maybe<string>): void {
      if (this.viewModel === undefined) return;

      // NOTE: 受注グループを変更すると、ルート回収の受注指定がリセットされる
      if (this.viewModel.routingGroup !== undefined) {
        this.$context.snackbar.warning(
          '担当グループを変更しますと、現在設定されているルート回収の指定がリセットされます',
          {
            described: true,
            timeout: 0,
          }
        );
        this.viewModel.routingGroup = undefined;
      }

      this.viewModel.onChangeOrderGroup();
    },
    async onIrregularTaskDurationChange(): Promise<void> {
      // その他のタスクはコンテナありの車と違って IGenerationSiteTaskDuration を継承したクラスを
      // 通して管理していないので、若干気持ち悪いがここで調整している
      if (this.viewModel === undefined) return;
      await Vue.nextTick();
      if (
        !this.viewModel.irregularTask.hoursAtGenerationSite ||
        this.viewModel.irregularTask.hoursAtGenerationSite < 0
      ) {
        this.viewModel.irregularTask.hoursAtGenerationSite = 0;
      }
      if (
        !this.viewModel.irregularTask.minutesAtGenerationSite ||
        this.viewModel.irregularTask.minutesAtGenerationSite < 0
      ) {
        this.viewModel.irregularTask.minutesAtGenerationSite = 0;
      }
      const secsOfHours = this.viewModel.irregularTask.hoursAtGenerationSite * 60 * 60;
      const secsOfMinutes = this.viewModel.irregularTask.minutesAtGenerationSite * 60;
      const [hoursValue, minutesValue] = getHoursAndMinutesOf(secsOfHours + secsOfMinutes);
      this.viewModel.irregularTask.hoursAtGenerationSite = hoursValue;
      this.viewModel.irregularTask.minutesAtGenerationSite = minutesValue;
      this.viewModel.onIrregularTaskChange();
    },
    onChangeHelperEnabled(value: boolean): void {
      if (!this.viewModel) return;
      this.viewModel.driverAssignmentType = value
        ? DriverAssignmentType.Distinguished
        : DriverAssignmentType.NotDistinguished;
    },
    async onChangeAssignableDrivers(driverNum: number, assignableDrivers: IOrderAssignableDriver[]): Promise<void> {
      if (this.viewModel === undefined) return;
      this.viewModel.driverNum = driverNum;
      this.viewModel.assignableDrivers = [...assignableDrivers];

      this.viewModel.onChangeAssignableDrivers();
      await this.updateAssignableDriverAttendances();
    },
    async onChangeDate(date: Date): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);

      const isPastDate = isBefore(date, startOfToday());
      if (isPastDate) {
        this.$context.snackbar.warning('過去の日付が選択されました。\n入力に間違いがないかご確認ください。');
      }

      // NOTE: 日付が変更されるとルート化可能な受注も変わるため設定をリセットする
      if (this.viewModel.routingGroup !== undefined) {
        this.$context.snackbar.warning('到着日時を変更しますと、現在設定されているルート回収の指定がリセットされます', {
          described: true,
          timeout: 0,
        });
        this.viewModel.routingGroup = undefined;
      }

      await this.updateAssignableDriverAttendances();
    },
    onChangeIsAssignableDriversCandidate(value: boolean): void {
      if (this.viewModel === undefined) return;
      this.viewModel.isAssignableDriversCandidate = value;
    },
    async onClickSetAllFieldWorkersToHelper() {
      if (!this.viewModel) return;

      const firstDriver = this.viewModel.assignableDrivers.find((driver) => driver.driverType === DriverType.Driver);

      // 補助員指定がない場合は自動で指定し、最初のドライバーを運転手として設定しておく
      if (this.viewModel.driverAssignmentType === DriverAssignmentType.NotDistinguished) {
        this.viewModel.driverAssignmentType = DriverAssignmentType.Distinguished;
        this.viewModel.assignableDrivers = firstDriver
          ? [{ driverId: firstDriver.driverId, driverType: DriverType.Operator }]
          : [];
      }

      const drivers = await this.driverApplicationService.getAll();
      const currentDriverIdSet = new Set(this.viewModel.assignableDrivers.map((d) => d.driverId));
      const fieldWorkers = drivers
        .filter((driver) => driver.employmentStatus === EmploymentStatus.FieldWorker)
        .filter((driver) => !currentDriverIdSet.has(driver.id))
        .map((driver) => {
          return { driverId: driver.id, driverType: DriverType.Helper };
        });
      const uniqueHelpers = _.uniqBy([...this.viewModel.assignableDrivers, ...fieldWorkers], 'driverId');

      // 補助員の人数以上になる場合は候補扱いにする
      if (uniqueHelpers.length >= this.viewModel.driverNum) {
        this.onChangeIsAssignableDriversCandidate(true);
      }

      this.onChangeAssignableDrivers(this.viewModel.driverNum, uniqueHelpers);
    },
    onRemoveUploadingFile(fileName: string): void {
      if (this.viewModel === undefined) return;
      this.viewModel.attachmentsToAdd = this.viewModel.attachmentsToAdd.filter((attachment) => {
        return fileName !== attachment.name;
      });
    },
    /**
     * 指定乗務員
     */
    async updateAssignableDriverAttendances(): Promise<void> {
      const viewModel = this.viewModel;
      if (viewModel === undefined) return;

      if (viewModel.assignableDrivers.length === 0) {
        viewModel.assignableDriverAttendances = [];
        return;
      }

      const assignableDriverIds = viewModel.assignableDrivers.map((assignableDriver) => assignableDriver.driverId);

      const driverAttendanceApplicationService = this.$context.applications.get(driverAttendanceSymbol);
      const date = viewModel.defaultDateCollectablePeriodItem.getDate();
      const driverAttendances = await Promise.all(
        assignableDriverIds.map((driverId) => driverAttendanceApplicationService.getListOfDriver(driverId, date, date))
      );

      viewModel.assignableDriverAttendances = driverAttendances.flat();
      viewModel.validateCarUsage();
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    async onClickCancelButton(): Promise<void> {
      if (!this.viewModel?.order) throw new Error('Order is not found');
      if (this.viewModel.order.createdVia === OrderCreatedVia.Reservation) {
        await new Promise<void>((resolve) => {
          this.isCancelOrderViaReservationConfirmDialogActive = true;
          this.confirmCancelViaReservation = () => resolve();
        });

        this.isCancelOrderViaReservationConfirmDialogActive = false;
      }

      if (this.viewModel.routingGroup !== undefined) {
        // NOTE: ルート回収でグループ設定されている場合はリセット確認のダイアログを表示する
        await new Promise<void>((resolve) => {
          this.isDeleteRoutingGroupConfirmActive = true;
          this.confirmDeleteRoutingGroup = () => resolve();
        });
      }

      if (this.viewModel.canUseErp && this.viewModel.erpOrderForm !== undefined) {
        await new Promise<void>((resolve) => {
          this.isConfirmDeleteErpOrderDialogActive = true;
          this.confirmDeleteErpOrder = () => resolve();
        });
      }

      await this.onConfirmCancelOrder();
    },
    async onConfirmCancelOrder(): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      if (this.checkClientAndGenerationSiteConsistency() === false) return;
      this.viewModel.isRegistering = true;
      const orderEntity = this.viewModel.order!;
      await this.orderApplicationService.cancel(orderEntity.persistentId);

      this.viewModel.updatedOrder = orderEntity;
      this.viewModel.isRegistering = false;

      this.$rinGtm.push(RinEventNames.CANCEL_ORDER, {
        [AdditionalInfoKeys.ORDER_ID]: orderEntity.persistentId,
        [AdditionalInfoKeys.REFERRER]: PageNames.ORDER_FORM,
      });

      this.$context.snackbar.success('受注情報のキャンセル完了');

      if (this.viewModel.canUseErp) await this.deleteErpOrder();

      await this.close();
    },
    onAbortCancelOrder(): void {
      this.isCancelOrderViaReservationConfirmDialogActive = false;
    },
    async onClickDeleteButton(): Promise<void> {
      if (!this.viewModel?.order) throw new Error('Order is not found');
      this.dialogOrder = this.viewModel.order;
      if (this.viewModel.order.createdVia === OrderCreatedVia.Reservation) {
        await new Promise<void>((resolve) => {
          this.isDeleteOrderViaReservationConfirmDialogActive = true;
          this.confirmDeleteViaReservation = () => resolve();
        });

        this.isDeleteOrderViaReservationConfirmDialogActive = false;
      } else {
        await new Promise<void>((resolve) => {
          this.isDeleteConfirmDialogActive = true;
          this.confirmDeleteOrder = () => resolve();
        });
      }

      // NOTE: ルート回収でグループ設定されている場合はリセット確認のダイアログを表示する
      if (this.viewModel.routingGroup !== undefined) {
        await new Promise<void>((resolve) => {
          this.isDeleteRoutingGroupConfirmActive = true;
          this.confirmDeleteRoutingGroup = () => resolve();
        });
      }

      this.$rinGtm.push(RinEventNames.DELETE, {
        [AdditionalInfoKeys.ORDER_ID]: this.viewModel.order.persistentId,
        [AdditionalInfoKeys.TARGET]: FormTargetParams.ORDER,
        [AdditionalInfoKeys.REFERRER]: PageNames.ORDER_FORM,
      });

      await this.onConfirmDeleteOrder();
    },
    async onClickRevertCancelButton(): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      if (this.checkClientAndGenerationSiteConsistency() === false) return;
      this.viewModel.isRegistering = true;
      const orderEntity = this.viewModel.order!;
      await this.orderApplicationService.activate(orderEntity.persistentId);

      this.viewModel.updatedOrder = orderEntity;
      this.viewModel.isRegistering = false;

      this.$rinGtm.push(RinEventNames.REVERT_CANCEL_ORDER, {
        [AdditionalInfoKeys.ORDER_ID]: orderEntity.persistentId,
        [AdditionalInfoKeys.REFERRER]: PageNames.ORDER_FORM,
      });

      this.$context.snackbar.success('受注情報のキャンセル解除完了');
      await this.close();
    },
    async onConfirmDeleteOrder(): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      if (this.checkClientAndGenerationSiteConsistency() === false) return;

      const orderEntity = this.viewModel.order!;
      // 繰り返し受注の場合のダイアログ表示
      let includeFollowingRecurringOrders: Maybe<boolean>;
      if (orderEntity.recurringSettings) {
        includeFollowingRecurringOrders = await (this.$refs.recurringOrderDeleteDialog as any).open();
        if (includeFollowingRecurringOrders === undefined) return;
      }

      // 複数候補日がある場合は、候補日の削除か受注そのものの削除かを選ぶダイアログを表示する
      let isDeleteCandidateDate: Maybe<boolean>;
      if (orderEntity.hasCandidateDates()) {
        isDeleteCandidateDate = await (this.$refs.planCandidateDatesDeleteDialog as any).open();
        if (isDeleteCandidateDate === undefined) return;
      }

      if (isDeleteCandidateDate) {
        // 候補日の削除の場合は、候補日の削除を実行する
        const data = orderEntity;
        // 複数候補日の中でスケジュールで選択されている日付を除外する
        if (
          data.plan !== undefined &&
          data.plan.candidateDates !== undefined &&
          orderEntity.editScheduleDate !== undefined
        ) {
          data.plan.candidateDates = data.plan.candidateDates.filter((date) => {
            if (orderEntity.editScheduleDate === undefined) {
              return false;
            }
            return !isSameDay(date.date, orderEntity.editScheduleDate);
          });
          // 候補日の削除を行った場合は最も若い日の値をdeprecated側にセットする
          const firstItem = data.plan.candidateDates[0];
          data.date = firstItem.date;
          data.collectablePeriodTemplateName = firstItem.collectablePeriodTemplateName;
          data.collectablePeriodStart = firstItem.collectablePeriodStart;
          data.collectablePeriodEnd = firstItem.collectablePeriodEnd;
          data.unloadDate = firstItem.unloadDate;
          // 複数候補日が1になった場合はcandidateDatesをundefinedにしてfixedに変換する
          if (data.plan.candidateDates.length === 1) {
            data.plan.fixed = {
              date: firstItem.date,
              collectablePeriodStart: firstItem.collectablePeriodStart,
              collectablePeriodEnd: firstItem.collectablePeriodEnd,
              collectablePeriodTemplateName: firstItem.collectablePeriodTemplateName,
              unloadDate: firstItem.unloadDate,
            };
            data.plan.candidateDates = undefined;
          }
        }
        // __typename削除
        // ここに入っているplan、plan.fixed、plan.candidateDatesは__typenameが入った形になってしまっている
        // 送信時のスキーマの型にはもちろん必要なくそのまま送ると型エラーが起きるため入れている
        // 自動生成された型を参照している部分などが原因である気がしているので後々修正予定
        delete data.plan?.__typename;
        delete data.plan?.fixed?.__typename;
        data.plan?.candidateDates?.forEach((candidateDate) => delete candidateDate.__typename);
        if (data.date === undefined) {
          throw new Error('date is undefined');
        }

        const updateData: IUpdateData = {
          id: data.id,
          date: data.date,
          plan: convertOrderPlanToOrderPlanInput(
            data.plan,
            data.date,
            data.collectablePeriodTemplateName,
            data.collectablePeriodStart,
            data.collectablePeriodEnd,
            data.unloadDate
          ),
          orderGroupId: data.orderGroupId,
          generationSiteId: data.generationSiteId,
          collectablePeriodTemplateName: data.collectablePeriodTemplateName,
          collectablePeriodStart: data.collectablePeriodStart,
          collectablePeriodEnd: data.collectablePeriodEnd,
          generationSiteTasks: undefined, // undefined = 更新しない
          irregularTasks: undefined, // undefined = 更新しない
          durationAtGenerationSite: data.durationAtGenerationSite,
          routeCollectionAllowed: data.routeCollectionAllowed,
          preloadStatus: data.preloadStatus,
          unloadDate: data.unloadDate,
          assignedBaseSiteId: data.assignedBaseSiteId,
          // TODO: 処分場の入退場時間の後続リリースで削除
          assignedDisposalSiteIds: data.assignedDisposalSiteIds,
          // TODO: 処分場の入退場時間の後続リリースで削除
          disposalSiteAssignmentType: data.disposalSiteAssignmentType,
          assignedDisposalSitesAndType: {
            orderDisposalSites: data.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
              return {
                disposalSiteId: orderAssignedDisposalSite.disposalSiteId,
                durationAtEntrance: orderAssignedDisposalSite.durationAtEntrance,
                priority: orderAssignedDisposalSite.priority,
              };
            }),
            disposalSiteAssignmentType: data.disposalSiteAssignmentType,
          },
          assignableDriversAndNum: {
            driverAssignmentType: data.driverAssignmentType,
            assignableDrivers: data.assignableDrivers,
            minAssignedDriverNum: data.minAssignedDriverNum,
            maxAssignedDriverNum: data.maxAssignedDriverNum,
          },
          assignedCarId: data.assignedCarId,
          assignableCarTypeIds: data.assignableCarTypeIds,
          minAssignedCarNum: data.carNum,
          maxAssignedCarNum: data.carNum,
          carNum: data.carNum,
          note: data.note,
          noteForAssignedDriver: data.noteForAssignedDriver,
          attachmentsToAdd: [],
          attachmentsToRemove: [],
          avoidHighways: data.avoidHighways,
          fixedArrivalTime: data.fixedArrivalTime,
          isFixedArrivalTimeReportNeeded: data.isFixedArrivalTimeReportNeeded,
          marginTypeOfFixedArrivalTime: data.marginTypeOfFixedArrivalTime,
          marginOfFixedArrivalTime: data.marginOfFixedArrivalTime,
          routingGroup: data.routingGroup,
          fixedDisplayOnReservation: data.fixedDisplayOnReservation,
          fixedDisplayOnReservationName: data.fixedDisplayOnReservationName,
          schedulingPriority: data.schedulingPriority,
          includeFollowingRecurringOrders,
          status: data.status,
        };

        this.viewModel.isRegistering = true;
        const entity = await this.orderApplicationService.update(updateData);
        this.viewModel.updatedOrder = entity;
        this.$context.snackbar.success('候補日の削除完了');
        this.viewModel.isRegistering = false;
        // 候補日の削除が完了した場合パネルを閉じる
        await this.close();
        return;
      }

      this.viewModel.isRegistering = true;
      await this.orderApplicationService.delete(orderEntity.persistentId, includeFollowingRecurringOrders);

      this.viewModel.updatedOrder = orderEntity;
      this.viewModel.isRegistering = false;
      this.$context.snackbar.success('受注情報の削除完了');
      this.dialogOrder = undefined;

      if (this.viewModel.canUseErp) await this.deleteErpOrder(includeFollowingRecurringOrders);

      await this.close();
    },
    onAbortDeleteOrder(): void {
      this.isDeleteOrderViaReservationConfirmDialogActive = false;
    },
    async deleteErpOrder(includeFollowingRecurringOrders?: Maybe<boolean>): Promise<void> {
      ensure(this.viewModel);
      if (!this.viewModel.canUseErp) return;

      ensure(this.viewModel.order);
      try {
        await this.erpOrderApplicationService.deleteErpOrderByOrderId(
          this.viewModel.order.persistentId,
          includeFollowingRecurringOrders
        );
      } catch (error) {
        this.$context.snackbar.error('稼ぎ頭の受注が削除できませんでした');
      }
    },
    async onClickEditDisposalSite(disposalSite: IDisposalSiteEntity): Promise<void> {
      const viewModel = this.viewModel;
      if (viewModel === undefined) throw new Error(`viewModel is undefined`);
      const systemContext = this.$context;
      const order = viewModel.order;
      const formValues = viewModel.getFormValues();
      const formTitle = viewModel.title;
      const callbackPromise = async () => {
        await systemContext.panels.orderFormPanel.open(order, { formTitle, initialFormValues: formValues });
      };
      await this.$context.panels.disposalSiteFormPanel.open(disposalSite, {
        forceClose: true,
        registerButtonLabel: `編集完了して受注${viewModel.formMode === FormMode.Register ? '登録' : '編集'}を続ける`,
        closeCallback: callbackPromise,
      });
    },
    onOrderAssignedDisposalSitesChange(orderAssignedDisposalSites: IOrderAssignedDisposalSite[]): void {
      if (this.viewModel === undefined) throw new Error(`viewModel is undefined`);
      this.viewModel.orderAssignedDisposalSites = orderAssignedDisposalSites;
    },
    /**
     * Returns the default field edit status for a given set of form values and generation site.
     *
     * @param {IFormValues} formValues - The form values object.
     * @param {GenerationSiteEntity} generationSite - The generation site entity.
     * @param {boolean} isBatchRegister - Indicates if the order is batch register.
     * @param {Map<string, CarTypeEntity>} carTypeMap - The car type map.
     * @param {Map<string, ICarEntity>} carMap - The car map.
     * @returns {DefaultFormValueStatuses} - The default field edit statuses.
     */
    getDefaultFieldEditStatus(
      formValues: IFormValues,
      generationSite: GenerationSiteEntity,
      isBatchRegister: boolean,
      carTypeMap: Map<string, CarTypeEntity>,
      carMap: Map<string, ICarEntity>
    ): DefaultFormValueStatuses {
      // 車種と車番は受注グループによってフォームに設定されるデフォルト値が変わるため、
      // 以下では、受注グループを考慮した比較を行っている
      const generationSiteDefaultAssignableCarTypeIds = generationSite.defaultAssignableCarTypeIds.filter(
        (defaultAssignableCarTypeId) =>
          carTypeMap.getOrError(defaultAssignableCarTypeId).orderGroupId === formValues.orderGroupId
      );
      const assignableCarTypeIds = _.isEqual(
        formValues.assignableCarTypeIds,
        generationSiteDefaultAssignableCarTypeIds
      );

      const generationSiteDefaultAssignedCar = generationSite.defaultAssignedCarId
        ? carMap.getOrError(generationSite.defaultAssignedCarId)
        : undefined;
      const generationSiteDefaultAssignedCarId =
        generationSiteDefaultAssignedCar &&
        carTypeMap.getOrError(generationSiteDefaultAssignedCar.carType.id).orderGroupId === formValues.orderGroupId
          ? generationSite.defaultAssignedCarId
          : undefined;
      const assignedCarId = formValues.assignedCarId === generationSiteDefaultAssignedCarId;

      // TODO: 排出場のデフォルト指定乗務員が複数選択できるようになったらこちらも反映させる
      const assignableDrivers = _.isEqual(
        _.sortBy(formValues.assignableDrivers.map((assignableDriver) => assignableDriver.driverId)),
        [generationSite.defaultAssignedDriverId]
      );

      // TODO: 排出場のデフォルト指定乗務員が複数選択できるようになったらこちらも反映させる。デフォルトは NotDistinguished
      const driverAssignmentType = formValues.driverAssignmentType === DriverAssignmentType.NotDistinguished;

      const disposalSiteIds = _.isEqual(
        formValues.disposalSiteIds,
        arrayFromMaybe(generationSite.defaultAssignedDisposalSiteId)
      );
      const avoidHighways = formValues.avoidHighways === generationSite.defaultAvoidHighways;
      const routeCollectionAllowed = formValues.routeCollectionAllowed === generationSite.defaultRouteCollectionAllowed;

      // Only check when order is not batch register.
      // Otherwise return false.
      const collectablePeriodTemplate = isBatchRegister
        ? false
        : formValues.dateCollectablePeriodItems[0].collectablePeriodTemplateId ===
          generationSite.defaultCollectablePeriodTemplateId;

      const collectablePeriodStart = isBatchRegister
        ? false
        : formValues.dateCollectablePeriodItems[0].collectablePeriodTemplateId !== DesignatedTimePeriodOptionId
        ? true
        : formValues.dateCollectablePeriodItems[0].collectablePeriodStart ===
          generationSite.defaultCollectablePeriodStart;

      const collectablePeriodEnd = isBatchRegister
        ? false
        : formValues.dateCollectablePeriodItems[0].collectablePeriodTemplateId !== DesignatedTimePeriodOptionId
        ? true
        : formValues.dateCollectablePeriodItems[0].collectablePeriodEnd === generationSite.defaultCollectablePeriodEnd;

      const collectableDistinctTime = isBatchRegister
        ? false
        : formValues.dateCollectablePeriodItems[0].collectablePeriodTemplateId !== DistinctTimeOptionId
        ? true
        : formValues.dateCollectablePeriodItems[0].collectableDistinctTime ===
          generationSite.defaultCollectablePeriodStart;

      const collectablePeriod =
        collectablePeriodTemplate && collectablePeriodStart && collectablePeriodEnd && collectableDistinctTime;

      const preloadStatus = formValues.preloadStatus === generationSite.defaultPreloadStatus;

      const isFixedArrivalTimeReportNeeded =
        formValues.isFixedArrivalTimeReportNeeded === generationSite.defaultIsFixedArrivalTimeReportNeeded;
      const marginTypeOfFixedArrivalTime =
        formValues.marginTypeOfFixedArrivalTime === generationSite.defaultMarginTypeOfFixedArrivalTime;
      const marginOfFixedArrivalTime =
        formValues.marginOfFixedArrivalTime === generationSite.defaultMarginOfFixedArrivalTime;

      return {
        assignableCarTypeIds,
        assignedCarId,
        driverAssignmentType,
        assignableDrivers,
        disposalSiteIds,
        avoidHighways,
        routeCollectionAllowed,
        collectablePeriod,
        preloadStatus,
        isFixedArrivalTimeReportNeeded,
        marginTypeOfFixedArrivalTime,
        marginOfFixedArrivalTime,
      };
    },
    onClickCheckOrderAcceptanceButton(): void {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);

      this.$rinGtm.push(RinEventNames.ACCEPTANCE_CHECK, {
        [AdditionalInfoKeys.ORDER_ID]: this.viewModel.order?.persistentId ?? '',
      });

      this.orderAcceptanceCheckDialogValue = true;
    },
    onUpdateIsChecking(value: boolean): void {
      // TODO どこかに移植
      // チェック中にフォーカスが当たっていると不自然な動きになるので消す
      // 本当はフォーカスをもう少し構造的に制御できる仕組みが必要だが、ひとまず
      if (value && document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.orderFormPanel.updateDisplayedWidth(value);
    },
    onClickEditRecurringSettings(): void {
      if (this.viewModel === undefined) {
        throw new Error(`Impossible!`);
      }

      if (this.viewModel.recurringOrderSettings === undefined) {
        // 繰り返し設定がされていなかった場合にはデフォルトの設定を入れておく
        const dateCollectablePeriodItem = this.viewModel.defaultDateCollectablePeriodItem;
        this.recurringOrderSettingsDialogValue = new RecurringOrderSettingsDialogValue(
          buildDefault(dateCollectablePeriodItem.getDate()),
          DialogMode.New
        );
      } else if (this.viewModel.formMode === FormMode.Register) {
        this.recurringOrderSettingsDialogValue = new RecurringOrderSettingsDialogValue(
          this.viewModel.recurringOrderSettings,
          DialogMode.New
        );
      } else if (this.viewModel.formMode === FormMode.Edit) {
        this.recurringOrderSettingsDialogValue = new RecurringOrderSettingsDialogValue(
          this.viewModel.recurringOrderSettings,
          DialogMode.Edit
        );
      }
    },
    onClickEditBatchRegister(): void {
      if (this.viewModel === undefined) {
        throw new Error(`Impossible!`);
      }
      if (this.viewModel.formMode === FormMode.Edit) {
        // 現状、編集はサポートしないので落としておく
        throw new Error(`edit mode is not supported. Impossible.`);
      }
      (this.$refs.collectablePeriodRegisterDialog as any).open();
    },
    onClickRecurringOrderSettingsComplete(settings: Maybe<IRecurringOrderSettings>): void {
      if (this.viewModel === undefined) {
        throw new Error(`Impossible!`);
      }
      // 繰り返し受注の繰り返し設定の編集で日付を編集した場合に繰り返し受注から外れる.
      if (settings === undefined) this.viewModel.dateCollectablePeriodType = DateCollectablePeriodTypes.Default;
      else this.viewModel.dateCollectablePeriodType = DateCollectablePeriodTypes.Recurring;

      this.viewModel.recurringOrderSettings = settings;
      this.viewModel.resetOrderPlan();
    },
    onClickBatchRegisterComplete(dateCollectablePeriodItems: DateCollectablePeriodItem[]): void {
      if (this.viewModel === undefined) {
        throw new Error(`Impossible!`);
      }
      this.viewModel.dateCollectablePeriodType = DateCollectablePeriodTypes.BatchRegister;
      this.viewModel.dateCollectablePeriodItems = dateCollectablePeriodItems;

      // NOTE: 受注パネルで表示する車台数を dateCollectablePeriodItem.carNum の最大値とする。
      // フォーム自体は disabled され、実際には dateCollectablePeriodItem.carNum を送るため、実際には使われないが、
      // driverNumと整合した状態を保つことが重要である
      // carNumが小さい分には不整合とならないため、item.carNum <= viewModel.carNum を保つようにする
      // 一括登録される場合は carNum は undefined にならないはずだが、 type-guard は必要
      const maxCarNum = Math.max(...dateCollectablePeriodItems.map((item) => item.carNum ?? 1));
      this.viewModel.carNum = maxCarNum;
      this.viewModel.onCarNumChange(maxCarNum);
      // onCarNumChange で assignableDriver が更新されている可能性があるので、update 時の処理をかける
      this.updateAssignableDriverAttendances();

      // 標準作業は1つのみ登録される想定なので複数受注の一括登録時は設定をリセットする
      this.viewModel.fixedDisplayOnReservation = false;
      this.viewModel.fixedDisplayOnReservationName = undefined;

      // 候補日の一括登録を行った場合は複数候補日の情報を削除する
      this.viewModel.resetOrderPlan();
    },
    onClickRecurringOrderSettingsDelete(): void {
      if (this.viewModel === undefined) {
        throw new Error(`Impossible!`);
      }
      this.viewModel.recurringOrderSettings = undefined;
    },
    onValidationError(): void {
      this.$context.snackbar.error('入力に問題があります。確認してください。');
    },
    onClickEditPeriodInputButton() {
      if (this.viewModel === undefined) {
        throw new Error(`Impossible!`);
      }
      switch (this.viewModel.dateCollectablePeriodType) {
        case DateCollectablePeriodTypes.Recurring:
          this.onClickEditRecurringSettings();
          break;
        case DateCollectablePeriodTypes.BatchRegister:
          this.onClickEditBatchRegister();
          break;
        case DateCollectablePeriodTypes.Plan:
          this.onClickEditPlan();
      }
    },
    async onChangeDateCollectablePeriodTypes(type: DateCollectablePeriodTypes): Promise<void> {
      if (this.viewModel === undefined) {
        throw new Error(`Impossible!`);
      }

      if (
        this.viewModel.dateCollectablePeriodType !== DateCollectablePeriodTypes.Default &&
        type !== this.viewModel.dateCollectablePeriodType
      ) {
        const confirmDialogPromise = new Promise<boolean>((resolve) => {
          this.isDateCollectablePeriodTypeChangeConfirmDialogActive = true;
          this.dateCollectablePeriodTypeChangeConfirmDialogResolver = resolve;
        });
        // 編集確認中は、詳細設定に戻す
        const dateCollectablePeriodType = this.viewModel.dateCollectablePeriodType;
        this.viewModel.dateCollectablePeriodType = DateCollectablePeriodTypes.Default;
        if (await confirmDialogPromise) {
          // DateCollectablePeriodTypes.Reset は必ずここに入る

          // NOTE: リセット時に到着時刻テンプレートをデフォルトがセットされていればそれに戻す。
          // 一度でもセットされていれば、後で排出場をクリアしても最後に設定した排出場に紐付く到着日時デンプレート defaultCollectablePeriodTemplate を使う
          // 排出場にデフォルト到着日時テンプレートが設定されていない場合は受注パネルのデフォルトの値にリセットする。
          if (this.defaultCollectablePeriodTemplate !== undefined) {
            this.viewModel.resetDateCollectablePeriodItems(this.defaultCollectablePeriodTemplate);
          } else {
            // TODO: 排出場の到着時間が時間指定の場合にリセットすると到着時間を空でリセットしてしまうので、 orderDefault からデフォルトの到着時間テンプレートを取得して反映するようにしたい。
            this.viewModel.resetDateCollectablePeriodItems();
          }
          this.onClickRecurringOrderSettingsDelete();

          // リセット時に複数候補日の情報を削除する
          this.viewModel.resetOrderPlan();

          // NOTE: 複数候補日、一括登録時は受注のルート化を解除する
          if (type === DateCollectablePeriodTypes.Plan || type === DateCollectablePeriodTypes.BatchRegister) {
            if (this.viewModel.routingGroup !== undefined) {
              this.$context.snackbar.warning(
                '到着日時を変更しますと、現在設定されているルート回収の指定がリセットされます',
                {
                  described: true,
                  timeout: 0,
                }
              );
              this.viewModel.routingGroup = undefined;
            }
          }
        } else {
          this.viewModel.dateCollectablePeriodType = dateCollectablePeriodType;
          return;
        }
      }
      switch (type) {
        case DateCollectablePeriodTypes.Recurring:
          this.viewModel.resetOrderPlan();
          this.onClickEditRecurringSettings();
          break;
        case DateCollectablePeriodTypes.BatchRegister:
          // NOTE: 一括登録時は受注のルート化を解除する
          if (this.viewModel.routingGroup !== undefined) {
            this.$context.snackbar.warning(
              '到着日時を変更しますと、現在設定されているルート回収の指定がリセットされます',
              {
                described: true,
                timeout: 0,
              }
            );
            this.viewModel.routingGroup = undefined;
          }
          this.viewModel.resetOrderPlan();
          this.onClickEditBatchRegister();
          break;
        case DateCollectablePeriodTypes.Plan:
          // NOTE: 複数候補日登録時は受注のルート化を解除する
          if (this.viewModel.routingGroup !== undefined) {
            this.$context.snackbar.warning(
              '到着日時を変更しますと、現在設定されているルート回収の指定がリセットされます',
              {
                described: true,
                timeout: 0,
              }
            );
            this.viewModel.routingGroup = undefined;
          }
          this.onClickEditPlan();
          break;
      }
    },

    async onClickCreateGenerationSite(initialGenerationSiteName?: string): Promise<void> {
      const viewModel = this.viewModel;
      if (viewModel === undefined) throw new Error(`viewModel is undefined`);

      // NOTE: 統合のときはここを調整
      this.$rinGtm.push(RinEventNames.OPEN_FORM, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.GENERATION_SITE,
        [AdditionalInfoKeys.REFERRER]: PageNames.ORDER_FORM,
      });

      const systemContext = this.$context;
      // NOTE: Reservationの場合は viewModel.reservation になる。統合のとき注意
      const order = viewModel.order;
      const formValues = viewModel.getFormValues();
      const formTitle = viewModel.title;

      const callbackPromise = async (createdGenerationSite: Maybe<AggregatedGenerationSiteEntity>) => {
        if (createdGenerationSite) {
          formValues.clientId = createdGenerationSite.clientId;
          formValues.generationSiteId = createdGenerationSite.id;
        }

        await systemContext.panels.orderFormPanel.open(order, { formTitle, initialFormValues: formValues });

        this.onChangeGenerationSite();
      };

      /** Reservationではこんな感じのコールバックになる
      const callbackPromiseFromReservation = async (createdGenerationSite: Maybe<AggregatedGenerationSiteEntity>) => {
        if (createdGenerationSite) {
          formValues.clientId = createdGenerationSite.clientId;
          formValues.generationSiteId = createdGenerationSite.id;
        }

        await systemContext.panels.reservationFormPanel.open(reservation, {
          formTitle,
          initialFormValues: formValues,
          scheduleDate: viewModel.scheduleDate,
        });


        this.onChangeGenerationSite();
      };
       */

      await this.$context.panels.generationSiteFormPanel.open(undefined, {
        forceClose: true,
        registerButtonLabel: `登録完了して受注${viewModel.formMode === FormMode.Register ? '登録' : '編集'}を続ける`,
        disableContinueRegistrationButton: true,
        initialFormValues: {
          clientId: formValues.clientId,
          name: initialGenerationSiteName,
        },
        closeCallback: callbackPromise,
      });
    },

    async onChangeGenerationSite(): Promise<void> {
      const viewModel = this.viewModel;
      if (!viewModel) return;

      // Selecting client.
      // Only when generation site was selected without selecting client before.
      if (viewModel.clientId === undefined && viewModel.generationSite) {
        const clientApplicationService = this.$context.applications.get(clientSymbol);
        const client = await clientApplicationService.getById(viewModel.generationSite.clientId);
        viewModel.setClientOnGenerationSiteChange(client);
      }

      // Set order defaults for selected generation site when creating new order.
      if (viewModel.formMode === FormMode.Register) {
        if (viewModel.generationSiteId && viewModel.generationSite) {
          const formValues = viewModel.getFormValues();

          // NOTE: 排出場に defaultCollectablePeriodTemplate が設定されていれば、そのテンプレートが orderDefault として返される仕組みになっている
          const orderDefault = await this.orderApplicationService.getOrderDefaultByGenerationSiteId(
            viewModel.generationSiteId
          );
          const orderDefaultService: IOrderDefaultService = new OrderDefaultService(orderDefault);

          const defaultRouteCollectionAllowed = orderDefaultService.defaultRouteCollectionAllowed();
          const defaultIsFixedArrivalTimeReportNeeded = orderDefaultService.defaultIsFixedArrivalTimeReportNeeded();
          const defaultMarginTypeOfFixedArrivalTime = orderDefaultService.defaultMarginTypeOfFixedArrivalTime();
          const defaultMarginOfFixedArrivalTime = orderDefaultService.defaultMarginOfFixedArrivalTime();

          // Note: Reservationでは営業日の処理がないかも？
          if (!this.businessDaysService) throw new Error('Business day service not initialized.');
          const { defaultPreloadStatus, dateCollectablePeriodItemsWithUpdatedUnloadDate } =
            orderDefaultService.defaultPreloadStatusWithUpdatedUnloadDate(
              formValues.dateCollectablePeriodItems,
              this.businessDaysService
            );

          this.defaultCollectablePeriodTemplate =
            viewModel.generationSite.defaultCollectablePeriodStart === undefined &&
            viewModel.generationSite.defaultCollectablePeriodEnd === undefined
              ? orderDefaultService.defaultCollectablePeriodTemplate(viewModel.collectablePeriodTemplateEntities)
              : undefined;

          orderDefaultService.updateFormValuesWithDefaults(
            formValues,
            defaultRouteCollectionAllowed,
            defaultPreloadStatus,
            dateCollectablePeriodItemsWithUpdatedUnloadDate,
            defaultIsFixedArrivalTimeReportNeeded,
            defaultMarginTypeOfFixedArrivalTime,
            defaultMarginOfFixedArrivalTime,
            this.defaultCollectablePeriodTemplate,
            viewModel.generationSite.defaultCollectablePeriodStart,
            viewModel.generationSite.defaultCollectablePeriodEnd
          );

          viewModel.routeCollectionAllowed = formValues.routeCollectionAllowed;
          viewModel.isFixedArrivalTimeReportNeeded = formValues.isFixedArrivalTimeReportNeeded;
          viewModel.marginTypeOfFixedArrivalTime = formValues.marginTypeOfFixedArrivalTime;
          const [marginOfFixedArrivalTimeHours, marginOfFixedArrivalTimeMinutes] = getHoursAndMinutesOf(
            formValues.marginOfFixedArrivalTime
          );
          viewModel.marginOfFixedArrivalTimeHours = marginOfFixedArrivalTimeHours;
          viewModel.marginOfFixedArrivalTimeMinutes = marginOfFixedArrivalTimeMinutes;
          viewModel.dateCollectablePeriodItems = formValues.dateCollectablePeriodItems;

          await this.onSelectPreload(formValues.preloadStatus);
        }
      }

      viewModel.onChangeGenerationSite(false);
      await this.updateAssignableDriverAttendances();
    },

    // ROrderGenerationSiteFormに置きたい
    async onClickEditGenerationSite(): Promise<void> {
      const viewModel = this.viewModel;
      if (viewModel === undefined) throw new Error(`viewModel is undefined`);
      if (viewModel.generationSiteId === undefined) throw new Error(`generationSite is undefined!`);
      const service = this.$context.applications.get(generationSiteSymbol);
      const [generationSite] = await service.getByIds([viewModel.generationSiteId]);
      const systemContext = this.$context;
      const order = viewModel.order;
      const formValues = viewModel.getFormValues();
      const formTitle = viewModel.title;
      const defaultDurationAtEntrance = generationSite.defaultDurationAtEntrance;
      const carTypeMap = mapEntity(viewModel.carTypes);
      const carMap = mapEntity(viewModel.cars);

      // REVIEW: なんだかとても大変なことになっているのでROrderFormにあるやつをそのまま使うことにする
      const defaultFieldEdited = this.getDefaultFieldEditStatus(
        formValues,
        generationSite,
        this.isBatchRegister,
        carTypeMap,
        carMap
      );

      if (!this.businessDaysService) throw new Error('Business day service not initialized.');

      // callbackPromise は実行コンテキストが変わるため `this` を使ってはいけない。参照を変数として持って使うようにする。
      const businessDaysService = this.businessDaysService;
      const orderApplicationService = this.orderApplicationService;

      this.$rinGtm.push(RinEventNames.OPEN_FORM, {
        [AdditionalInfoKeys.GENERATION_SITE_ID]: generationSite.persistentId,
        [AdditionalInfoKeys.TARGET]: FormTargetParams.GENERATION_SITE,
        [AdditionalInfoKeys.REFERRER]: PageNames.ORDER_FORM,
      });

      // TODO: 排出場パネルを閉じた時にcallbackを呼ばないように修正する
      const callbackPromise = async (
        callbackedGenerationSite: Maybe<AggregatedGenerationSiteEntity> = generationSite
      ) => {
        // 排出場で設定しているデフォルトの値を持ってくるフィールドがユーザーによって変更されていなかった場合、
        // それはデフォルトの値を設定したいものだと解釈して、排出場のパネルで何か値を変更していた場合に
        // その値を受注パネルに再度設定し直すという事をしている

        // 受注に設定されている値を優先する
        // 車種・車番はどちらか一方しか設定できないため、受注に排出場の設定と異なる車種車番情報が設定されていない場合のみ排出場デフォルト値を反映する
        // 例: 受注に車番設定あり, 排出場に車種設定し更新 -> 受注上は車番設定が残り、車種は設定なし
        if (
          defaultFieldEdited.assignableCarTypeIds &&
          callbackedGenerationSite.defaultAssignableCarTypeIds.length !== 0 &&
          formValues.assignedCarId === undefined
        ) {
          // 指定車種の orderGroupId と オーダーの orderGroupId が別だった場合、グループと車種が矛盾する事になるので
          // assignableCarTypeIds から違うものは消しておく
          formValues.assignableCarTypeIds = callbackedGenerationSite.defaultAssignableCarTypeIds.filter(
            (assignableCarTypeId) => carTypeMap.getOrError(assignableCarTypeId).orderGroupId === formValues.orderGroupId
          );
        }
        if (
          defaultFieldEdited.assignedCarId &&
          callbackedGenerationSite.defaultAssignedCarId &&
          formValues.assignableCarTypeIds.length === 0
        ) {
          // 指定車番から推測した車種の orderGroupId とオーダーの orderGroupId が別だった場合、グループと車番が矛盾する事に
          // なるので assignedCarId は空になるようにする
          const assignedCar = callbackedGenerationSite.defaultAssignedCarId
            ? carMap.getOrError(callbackedGenerationSite.defaultAssignedCarId)
            : undefined;
          const assignableCarType = assignedCar ? carTypeMap.getOrError(assignedCar.carType.id) : undefined;
          formValues.assignedCarId =
            assignableCarType && assignableCarType.orderGroupId === formValues.orderGroupId
              ? callbackedGenerationSite.defaultAssignedCarId
              : undefined;
        }

        if (defaultFieldEdited.assignableDrivers && callbackedGenerationSite.defaultAssignedDriverId) {
          formValues.driverAssignmentType = DriverAssignmentType.NotDistinguished;
          formValues.assignableDrivers = [
            {
              driverType: DriverType.Driver,
              driverId: callbackedGenerationSite.defaultAssignedDriverId,
            },
          ];
        }
        if (defaultFieldEdited.disposalSiteIds && callbackedGenerationSite.defaultAssignedDisposalSiteId) {
          formValues.disposalSiteIds = arrayFromMaybe(callbackedGenerationSite.defaultAssignedDisposalSiteId);
        }
        if (defaultFieldEdited.avoidHighways) {
          formValues.avoidHighways = callbackedGenerationSite.defaultAvoidHighways;
        }
        if (
          formValues.generationSiteTaskCategory !== GenerationSiteTaskCategory.Irregular &&
          formValues.durationAtGenerationSite !== undefined
        ) {
          // この処理は賛否両論ありそうだが、durationAtEntrance を単品で保持していない以上、
          // どうしてもいい加減な処理になってしまう。以前、排出場のデフォルト入退場時間をいじって
          // 戻ってきた時に即座に反映されないのかという意見をもらった事があったので、若干いい加減になる
          // 事を覚悟でこの仕様にしている。
          const diff = defaultDurationAtEntrance - callbackedGenerationSite.defaultDurationAtEntrance;
          formValues.durationAtGenerationSite = Math.max(formValues.durationAtGenerationSite - diff, 0);
        }

        // Get order default values again after generation site is updated.
        const orderDefault = await orderApplicationService.getOrderDefaultByGenerationSiteId(
          callbackedGenerationSite.id
        );
        const orderDefaultService: IOrderDefaultService = new OrderDefaultService(orderDefault);
        const defaultRouteCollectionAllowed = orderDefaultService.defaultRouteCollectionAllowed();
        const defaultIsFixedArrivalTimeReportNeeded = orderDefaultService.defaultIsFixedArrivalTimeReportNeeded();
        const defaultMarginTypeOfFixedArrivalTime = orderDefaultService.defaultMarginTypeOfFixedArrivalTime();
        const defaultMarginOfFixedArrivalTime = orderDefaultService.defaultMarginOfFixedArrivalTime();

        const { defaultPreloadStatus, dateCollectablePeriodItemsWithUpdatedUnloadDate } =
          orderDefaultService.defaultPreloadStatusWithUpdatedUnloadDate(
            formValues.dateCollectablePeriodItems,
            businessDaysService
          );

        // NOTE: 排出場でデフォルト到着時間テンプレートが変更されていれば、受注パネルのデフォルト値を更新する
        // 空の場合は共通のデフォルト設定を取得する
        if (callbackedGenerationSite.defaultCollectablePeriodTemplate !== undefined) {
          this.defaultCollectablePeriodTemplate = callbackedGenerationSite.defaultCollectablePeriodTemplate;
        }

        orderDefaultService.updateFormValuesWithDefaults(
          formValues,
          defaultFieldEdited.routeCollectionAllowed ? defaultRouteCollectionAllowed : undefined,
          defaultFieldEdited.preloadStatus ? defaultPreloadStatus : undefined,
          defaultFieldEdited.preloadStatus ? dateCollectablePeriodItemsWithUpdatedUnloadDate : undefined,
          defaultFieldEdited.isFixedArrivalTimeReportNeeded ? defaultIsFixedArrivalTimeReportNeeded : undefined,
          defaultFieldEdited.marginTypeOfFixedArrivalTime ? defaultMarginTypeOfFixedArrivalTime : undefined,
          defaultFieldEdited.marginOfFixedArrivalTime ? defaultMarginOfFixedArrivalTime : undefined,
          defaultFieldEdited.collectablePeriod ? callbackedGenerationSite.defaultCollectablePeriodTemplate : undefined,
          defaultFieldEdited.collectablePeriod ? callbackedGenerationSite.defaultCollectablePeriodStart : undefined,
          defaultFieldEdited.collectablePeriod ? callbackedGenerationSite.defaultCollectablePeriodEnd : undefined
        );

        formValues.clientId = callbackedGenerationSite.clientId;

        await systemContext.panels.orderFormPanel.open(order, { formTitle, initialFormValues: formValues });
      };
      await this.$context.panels.generationSiteFormPanel.open(generationSite, {
        forceClose: true,
        registerButtonLabel: `編集完了して受注${viewModel.formMode === FormMode.Register ? '登録' : '編集'}を続ける`,
        closeCallback: callbackPromise,
      });
    },

    onClickEditPlan(): void {
      if (this.viewModel === undefined) {
        throw new Error(`Impossible!`);
      }
      // ダイアログを開く際に編集用データをセットする
      this.viewModel.setEditOrderPlanCollectablePeriodItems(
        _.cloneDeep(this.viewModel.orderPlanCollectablePeriodItems)
      );
      (this.$refs.planCandidateDatesDialog as any).open();
    },
    // DateCollectablePeriodItems で指定されている車台数が全て同じ値かをチェック
    isCarNumOfDateCollectablePeriodItemsSameValue(dateCollectablePeriodItems: DateCollectablePeriodItem[]): boolean {
      if (dateCollectablePeriodItems.length === 0) return true;

      const carNum = dateCollectablePeriodItems[0].carNum;
      if (
        dateCollectablePeriodItems.every((dateCollectablePeriodItem) => dateCollectablePeriodItem.carNum === carNum)
      ) {
        return true;
      }

      return false;
    },
  },
});
